import { processApi } from 'api'

interface Params {
  signedUrl: string
  file: File
}

export const uploadProcessFileApi = ({ signedUrl, file }: Params) =>
  processApi.put<''>(signedUrl, file, {
    headers: { 'Content-Type': file.type || 'application/octet-stream', 'x-goog-content-length-range': '1,1000000' },
  })
