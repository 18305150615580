import { WppButton, WppIconGear, WppSpinner } from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import clsx from 'clsx'
import { useState, useEffect, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useProcessApi } from 'api/process/queries/useFetchProcessApi'
import { Permission } from 'constants/permission'
import { useHasPermission } from 'hooks/useHasPermissions'
import { showEditProcessModal, useEditProcessModal } from 'pages/processBuilder/editProcessModal/EditProcessModal'
import { EmptyState } from 'pages/processBuilder/emptyState/EmptyState'
import styles from 'pages/processBuilder/ProcessBuilder.module.scss'
import { CircularProcess1 } from 'pages/processBuilder/templates/circular1/CircularProcess1'
import { getPreviewImageUrl, getInitialValues } from 'pages/processBuilder/utils'
import { Templates } from 'types/process/process'

export const ProcessBuilder = () => {
  const {
    osContext: { tenant },
  } = useOs()
  const { data, refetch, isLoading } = useProcessApi({
    params: {
      tenantId: tenant.id,
    },
  })
  const [config, setConfig] = useState(getInitialValues(data))
  const { hasPermission } = useHasPermission()
  const isAdmin = hasPermission(Permission.OS_ADMIN_SETTING_ACCESS)
  const { visible: isEditMode } = useEditProcessModal()
  const { t } = useTranslation()

  useEffect(() => {
    setConfig(getInitialValues(data))
  }, [data])

  const resetEditState = useCallback(async () => {
    const { data: updatedData } = await refetch()
    updatedData?.data && setConfig(getInitialValues(updatedData.data) as any)
  }, [refetch])

  const handleOpenEdit = useCallback(() => {
    showEditProcessModal({ onChange: setConfig, resetEditState: resetEditState })
  }, [resetEditState])

  const Template = useMemo(() => {
    switch (config.template) {
      case Templates.Circular1:
      case Templates.Circular2:
      default:
        return CircularProcess1
    }
  }, [config.template])

  if (isLoading) return <WppSpinner className={styles.loader} size="l" />
  if (!data && !isEditMode) return <EmptyState isAdmin={isAdmin} onConfigureClick={handleOpenEdit} />

  return (
    <div
      className={clsx(styles.root, { [styles.editMode]: isEditMode })}
      style={{ backgroundImage: `url(${getPreviewImageUrl(config?.background)})` }}
    >
      <div className={styles.bgColor} style={{ background: config?.colors?.[0] }} />

      <Template config={config} isEditMode={isEditMode} />

      {isAdmin && !isEditMode && (
        <WppButton onClick={handleOpenEdit} className={styles.editBtn} variant="primary">
          <WppIconGear slot="icon-start" />
          {t('common.edit')}
        </WppButton>
      )}
    </div>
  )
}
