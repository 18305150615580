import { WppTypography } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import clsx from 'clsx'
import { MouseEventHandler, useCallback, useMemo } from 'react'

import styles from 'pages/processBuilder/templates/circular1/CircularProcess1.module.scss'
import { Phase } from 'pages/processBuilder/templates/circular1/phase/Phase'
import { getPreviewImageUrl } from 'pages/processBuilder/utils'
import { ProcessFormConfig } from 'types/process/process'

export const Circular1Svg = ({
  activePhase,
  className,
  blurClassname,
  onPhaseEnter,
  onPhaseLeave,
  config,
  isEditMode,
}: {
  activePhase: MayBeNull<number>
  className?: string
  blurClassname?: string
  onPhaseEnter: (phase: number) => MouseEventHandler
  onPhaseLeave: MouseEventHandler
  config: ProcessFormConfig
  isEditMode: boolean
}) => {
  const isActivePhase = useCallback((phase: number) => phase === activePhase, [activePhase])
  const widgetColor = config.colors?.[1] || 'white'
  const parrentTitle = useMemo(
    () => (typeof activePhase === 'number' ? config.phases?.[activePhase]?.groupTitle : ''),
    [activePhase, config.phases],
  )

  return (
    <svg
      width="1280"
      height="910"
      viewBox="0 0 1280 910"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <foreignObject className={blurClassname} />
      <g transform="matrix(1, 0, 0, 1, -1.5, 67)">
        <g filter="url(#filter0_bd_138_22403)" className={styles.fade1}>
          <circle cx="641" cy="385.027" r="300" fill={widgetColor} fillOpacity="0.1" shapeRendering="crispEdges" />
        </g>
        <g filter="url(#filter1_bd_138_22403)" className={styles.fade2}>
          <circle cx="641" cy="385.027" r="258" fill={widgetColor} fillOpacity="0.15" shapeRendering="crispEdges" />
        </g>
        <g filter="url(#filter2_bd_138_22403)" className={styles.fade3}>
          <circle cx="641" cy="385.027" r="218" fill={widgetColor} fillOpacity="0.2" shapeRendering="crispEdges" />
        </g>
        <g filter="url(#filter3_d_138_22403)" className={styles.fade4}>
          <circle cx="641" cy="385.027" r="180" fill={widgetColor} fillOpacity="0.25" shapeRendering="crispEdges" />
        </g>
        <g filter="url(#filter4_d_138_22403)" className={styles.fade5}>
          <circle cx="641" cy="385.027" r="140" fill={widgetColor} fillOpacity="0.3" shapeRendering="crispEdges" />
        </g>

        {config?.phases?.map((item, index) => (
          <Phase
            key={index}
            onMouseEnter={onPhaseEnter(index)}
            onMouseLeave={onPhaseLeave}
            isActive={isActivePhase(index)}
            rotation={config?.phases ? (360 / config?.phases.length) * index : 0}
            phase={item}
            isEditMode={isEditMode}
          />
        ))}

        <g filter="url(#filter4_d_138_22403)" className={styles.fade6}>
          <path
            d="M746 385C746 443.54 698.556 491 640.01 491C581.464 491 534 443.54 534 385C534 326.459 581.464 279 640.01 279C698.556 279 746 326.459 746 385Z"
            fill={widgetColor}
          />
        </g>

        <foreignObject width={212} height={212} x={534} y={279.027} className={clsx(styles.logoWrap, styles.fade6)}>
          <WppTypography type="xl-heading" className={styles.phaseGroupTitle} style={{ opacity: parrentTitle ? 1 : 0 }}>
            {parrentTitle}
          </WppTypography>
          <img
            src={getPreviewImageUrl(config?.logo)}
            alt=""
            className={styles.logo}
            style={{ opacity: parrentTitle ? 0 : 1 }}
          />
        </foreignObject>
      </g>
      <defs>
        <filter
          id="filter0_bd_138_22403"
          x="301"
          y="57.0272"
          width="680"
          height="680"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="8" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_138_22403" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="20" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.541176 0 0 0 0 0.0156863 0 0 0 0 0.00784314 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="effect1_backgroundBlur_138_22403" result="effect2_dropShadow_138_22403" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_138_22403" result="shape" />
        </filter>
        <filter
          id="filter1_bd_138_22403"
          x="343"
          y="91.0271"
          width="596"
          height="596"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="8" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_138_22403" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="20" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.541176 0 0 0 0 0.0156863 0 0 0 0 0.00784314 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="effect1_backgroundBlur_138_22403" result="effect2_dropShadow_138_22403" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_138_22403" result="shape" />
        </filter>
        <filter
          id="filter2_bd_138_22403"
          x="383"
          y="131.027"
          width="516"
          height="516"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="8" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_138_22403" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="20" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.541176 0 0 0 0 0.0156863 0 0 0 0 0.00784314 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="effect1_backgroundBlur_138_22403" result="effect2_dropShadow_138_22403" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_138_22403" result="shape" />
        </filter>
        <filter
          id="filter3_d_138_22403"
          x="421"
          y="169.027"
          width="440"
          height="440"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="20" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.541176 0 0 0 0 0.0156863 0 0 0 0 0.00784314 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_138_22403" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_138_22403" result="shape" />
        </filter>
        <filter
          id="filter4_d_138_22403"
          x="461"
          y="209.027"
          width="360"
          height="360"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="20" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.541176 0 0 0 0 0.0156863 0 0 0 0 0.00784314 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_138_22403" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_138_22403" result="shape" />
        </filter>
        <filter
          id="filter5_d_138_22403"
          x="494"
          y="241"
          width="292"
          height="292"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="20" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.541176 0 0 0 0 0.0156863 0 0 0 0 0.00784314 0 0 0 0.4 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_138_22403" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_138_22403" result="shape" />
        </filter>
        <filter
          id="filter6_bd_138_22403"
          x="894"
          y="149"
          width="156"
          height="94"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_138_22403" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="10" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.541176 0 0 0 0 0.0156863 0 0 0 0 0.00784314 0 0 0 0.08 0" />
          <feBlend mode="normal" in2="effect1_backgroundBlur_138_22403" result="effect2_dropShadow_138_22403" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_138_22403" result="shape" />
        </filter>
        <filter
          id="filter7_bd_138_22403"
          x="251"
          y="149"
          width="139"
          height="94"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_138_22403" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="10" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.541176 0 0 0 0 0.0156863 0 0 0 0 0.00784314 0 0 0 0.08 0" />
          <feBlend mode="normal" in2="effect1_backgroundBlur_138_22403" result="effect2_dropShadow_138_22403" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_138_22403" result="shape" />
        </filter>
        <filter
          id="filter8_bd_138_22403"
          x="228"
          y="527"
          width="162"
          height="94"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_138_22403" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="10" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.541176 0 0 0 0 0.0156863 0 0 0 0 0.00784314 0 0 0 0.08 0" />
          <feBlend mode="normal" in2="effect1_backgroundBlur_138_22403" result="effect2_dropShadow_138_22403" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_138_22403" result="shape" />
        </filter>
        <filter
          id="filter9_bd_138_22403"
          x="551"
          y="-3"
          width="182"
          height="94"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_138_22403" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="10" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.541176 0 0 0 0 0.0156863 0 0 0 0 0.00784314 0 0 0 0.08 0" />
          <feBlend mode="normal" in2="effect1_backgroundBlur_138_22403" result="effect2_dropShadow_138_22403" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_138_22403" result="shape" />
        </filter>
        <filter
          id="filter10_bd_138_22403"
          x="555"
          y="679"
          width="175"
          height="94"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_138_22403" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="10" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.541176 0 0 0 0 0.0156863 0 0 0 0 0.00784314 0 0 0 0.08 0" />
          <feBlend mode="normal" in2="effect1_backgroundBlur_138_22403" result="effect2_dropShadow_138_22403" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_138_22403" result="shape" />
        </filter>
        <filter
          id="filter11_bd_138_22403"
          x="894"
          y="527"
          width="161"
          height="94"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_138_22403" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="10" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.541176 0 0 0 0 0.0156863 0 0 0 0 0.00784314 0 0 0 0.08 0" />
          <feBlend mode="normal" in2="effect1_backgroundBlur_138_22403" result="effect2_dropShadow_138_22403" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_138_22403" result="shape" />
        </filter>
        <radialGradient
          id="paint0_radial_138_22403"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(645.224 145.289) rotate(91.1259) scale(249.088 119.047)"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_138_22403"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(643.812 217.136) rotate(91.1259) scale(177.228 84.7026)"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_138_22403"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(644.533 180.401) rotate(91.1259) scale(213.97 102.263)"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint3_radial_138_22403"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(851.229 268.314) rotate(151.126) scale(245.784 117.468)"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint4_radial_138_22403"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(807.836 292.243) rotate(151.126) scale(196.231 93.7846)"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint5_radial_138_22403"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(832.192 495.974) rotate(-148.874) scale(224.757 107.418)"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint6_radial_138_22403"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(636.48 606.189) rotate(-88.8741) scale(226.306 108.158)"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint7_radial_138_22403"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(450.468 492.712) rotate(-28.8741) scale(221.007 105.626)"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint8_radial_138_22403"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(447.466 265.967) rotate(31.1259) scale(229.814 109.835)"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </radialGradient>
        <clipPath id="clip0_138_22403">
          <rect width="1265" height="767" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
