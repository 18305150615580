import { MayBeNull } from '@wpp-open/core'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/customProcess/components/ibm/IbmProcess.module.scss'
import { IbmSvg } from 'pages/customProcess/components/ibm/IbmSvg'
import { Phases } from 'pages/customProcess/components/ibm/types'

const phaseLinks: Record<Phases, string> = {
  [Phases.Brief]: './',
  [Phases.Discover]: '/custom/ibm/discover',
  [Phases.Decision]: '/custom/ibm/decision',
  [Phases.Design]: '/custom/ibm/design',
  [Phases.Deliver]: '/custom/ibm/deliver',
  [Phases.Adapt]: '/custom/ibm/adapt',
}

export const IbmProcess = () => {
  const [activePhase, setActivePhase] = useState<MayBeNull<Phases>>(null)
  const navigate = useNavigate()

  const handlePhaseEnter = useCallback(
    (phase: Phases) => () => {
      setActivePhase(phase)
    },
    [],
  )

  const handlePhaseLeave = useCallback(() => {
    setActivePhase(null)
  }, [])

  const handlePointClick = useCallback(
    (phase: Phases) => () => {
      navigate(phaseLinks[phase])
    },
    [navigate],
  )

  return (
    <Flex align="center" justify="center" className={styles.root}>
      <IbmSvg
        onPhaseEnter={handlePhaseEnter}
        onPhaseLeave={handlePhaseLeave}
        onPointClick={handlePointClick}
        activePhase={activePhase}
        className={styles.svg}
        pointClassName={styles.svgPoint}
      />
    </Flex>
  )
}
