import schemeImg from 'pages/customProcess/components/audible/assets/one pagers_provoke.png'
import { ProcessCard } from 'pages/customProcess/components/audible/processPhases/processCard/ProcessCard'

const Provoke = () => {
  return (
    <ProcessCard
      type="grow"
      title="Provoke"
      description="Develop a point of view on Audible’s key challenge"
      nextLink="/custom/audible/identify"
    >
      <img src={schemeImg} alt="" />
    </ProcessCard>
  )
}

export default Provoke
