import schemeImg from 'pages/customProcess/components/audible/assets/one pagers_strategize.png'
import { ProcessCard } from 'pages/customProcess/components/audible/processPhases/processCard/ProcessCard'

const Strategize = () => {
  return (
    <ProcessCard
      type="grow"
      title="Strategize"
      description="Decision on how Audible can address audience pain points through media"
      previousLink="/custom/audible/identify"
      nextLink="/custom/audible/plan"
    >
      <img src={schemeImg} alt="" />
    </ProcessCard>
  )
}

export default Strategize
