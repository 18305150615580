import clsx from 'clsx'
import { MouseEventHandler, useMemo, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/processBuilder/templates/circular1/phase/Phase.module.scss'
import { FormPhase as PhaseConfig } from 'types/process/process'
import { routesManager } from 'utils/routesManager'

export const Phase = ({
  onMouseEnter,
  onMouseLeave,
  isActive,
  rotation,
  phase,
  isEditMode,
}: {
  onMouseEnter: MouseEventHandler
  onMouseLeave: MouseEventHandler
  isActive: boolean
  rotation: number
  phase: PhaseConfig
  isEditMode: boolean
}) => {
  const navigate = useNavigate()
  const url = (phase.innerPageEnabled && phase?.id ? routesManager.phase.getUrl(phase?.id) : phase?.url) || ''

  const handleClick = useCallback(() => {
    navigate(url)
  }, [navigate, url])

  const { x: textX, y: textY } = useMemo(() => {
    const isCenteredX = rotation === 90 || rotation === 270
    const isCenteredY = rotation === 0 || rotation === 180

    return {
      x: rotation > 0 && rotation < 180 ? '280' : isCenteredY ? '0' : '-280',
      y: rotation > 90 && rotation < 270 ? '260' : isCenteredX ? '208' : '0',
    }
  }, [rotation])

  const isOverlayInverted = useMemo(() => rotation >= 90 && rotation < 270, [rotation])

  const overlayStart = useMemo(
    () => (phase?.overlayDescriptions && phase?.overlayDescriptions?.length < 3 ? 2 : 1),
    [phase?.overlayDescriptions],
  )

  const withLastSepatator = useMemo(
    () => phase?.overlayDescriptions && phase?.overlayDescriptions?.length > 1,
    [phase?.overlayDescriptions],
  )

  const isDescriptionBottom = useMemo(() => rotation >= 90 && rotation <= 270, [rotation])

  const isWideDescription = useMemo(() => rotation === 0 || rotation === 180, [rotation])

  const { textOrientation = 'center', textAlign = 'center' } = useMemo(
    () => ({
      textOrientation: rotation > 0 && rotation < 180 ? 'start' : rotation > 180 && rotation < 360 ? 'end' : 'center',
      textAlign: rotation > 0 && rotation < 180 ? 'left' : rotation > 180 && rotation < 360 ? 'right' : 'center',
    }),
    [rotation],
  )

  return (
    <g
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      transform={`rotate(${rotation} 641 385) translate(0, -8)`}
      className={clsx({ [styles.phaseAnimation]: !isEditMode, [styles.phaseInteractive]: url })}
      onClick={handleClick}
    >
      <g opacity={isActive ? 1 : 0}>
        <path
          d="M492 133.219C537.606 106.889 589.339 93.0271 642 93.0271C694.661 93.0271 746.394 106.889 792 133.219L642 393.027L492 133.219Z"
          fill={phase?.colors?.[0] || '#EE3023'}
          fillOpacity="0.8"
        />
        {isOverlayInverted ? (
          <>
            <path
              opacity="0.8"
              d="M 514.662 144.405 C 552.87 166.464 596.211 178.077 640.329 178.077 C 684.447 178.077 727.789 166.464 765.996 144.405 L 765.182 142.995 C 727.222 164.911 684.162 176.449 640.329 176.449 C 596.496 176.449 553.436 164.911 515.476 142.995 L 514.662 144.405 Z"
              fill="url(#paint0_radial_138_22403)"
              style={{ transformBox: 'fill-box', transformOrigin: '50% 50%' }}
              transform="matrix(-1, 0, 0, -1, -0.000011, 0) translate(0, 5)"
              id="curve1_inverted"
            />
            <path
              opacity="0.8"
              d="M 532.38 179.926 C 565.201 198.875 602.432 208.851 640.33 208.851 C 678.228 208.851 715.458 198.875 748.279 179.926 L 747.416 178.43 C 714.857 197.228 677.925 207.124 640.33 207.124 C 602.735 207.124 565.802 197.228 533.244 178.43 L 532.38 179.926 Z"
              fill="url(#paint0_radial_138_22403)"
              style={{ transformBox: 'fill-box', transformOrigin: '50% 50%' }}
              transform="matrix(-1, 0, 0, -1, 0.000028, -0.000032)"
              id="curve2_inverted"
            />
            <path
              opacity={withLastSepatator ? '0.8' : '0'}
              d="M 550.916 216.742 C 578.1 232.437 608.938 240.7 640.328 240.7 C 671.719 240.7 702.556 232.437 729.741 216.742 L 729.026 215.503 C 702.059 231.072 671.468 239.269 640.328 239.269 C 609.189 239.269 578.598 231.072 551.631 215.503 L 550.916 216.742 Z"
              fill="url(#paint0_radial_138_22403)"
              style={{ transformBox: 'fill-box', transformOrigin: '50% 50%' }}
              transform="matrix(-1, 0, 0, -1, 0.000052, 0.000006)"
              id="curve3_inverted"
            />
            <path
              d="M 550.916 216.742 C 578.1 232.437 608.938 240.7 640.328 240.7 C 671.719 240.7 702.556 232.437 729.741 216.742 L 729.026 215.503 C 702.059 231.072 671.468 239.269 640.328 239.269 C 609.189 239.269 578.598 231.072 551.631 215.503 L 550.916 216.742 Z"
              fill="transparent"
              style={{ transformBox: 'fill-box', transformOrigin: '50% 50%' }}
              transform="matrix(-1, 0, 0, -1, 0.000052, 0.000006)"
              id="curve4_inverted"
            />
          </>
        ) : (
          <>
            <path
              opacity="0.8"
              d="M 514.662 176.667 C 552.87 154.608 596.211 142.995 640.329 142.995 C 684.447 142.995 727.789 154.608 765.996 176.667 L 765.182 178.077 C 727.222 156.161 684.162 144.623 640.329 144.623 C 596.496 144.623 553.436 156.161 515.476 178.077 L 514.662 176.667 Z"
              fill="url(#paint0_radial_138_22403)"
              id="curve1"
              transform="translate(0, -5)"
            />
            <path
              opacity="0.8"
              d="M 532.379 207.355 C 565.2 188.406 602.431 178.43 640.329 178.43 C 678.227 178.43 715.457 188.406 748.278 207.355 L 747.415 208.851 C 714.856 190.053 677.924 180.157 640.329 180.157 C 602.734 180.157 565.801 190.053 533.243 208.851 L 532.379 207.355 Z"
              fill="url(#paint0_radial_138_22403)"
              id="curve2"
            />
            <path
              opacity={withLastSepatator ? '0.8' : '0'}
              d="M 550.917 239.461 C 578.101 223.766 608.939 215.503 640.329 215.503 C 671.72 215.503 702.557 223.766 729.742 239.461 L 729.027 240.7 C 702.06 225.131 671.469 216.934 640.329 216.934 C 609.19 216.934 578.599 225.131 551.632 240.7 L 550.917 239.461 Z"
              fill="url(#paint0_radial_138_22403)"
              id="curve3"
            />
            <path
              d="M 550.917 239.461 C 578.101 223.766 608.939 215.503 640.329 215.503 C 671.72 215.503 702.557 223.766 729.742 239.461 L 729.027 240.7 C 702.06 225.131 671.469 216.934 640.329 216.934 C 609.19 216.934 578.599 225.131 551.632 240.7 L 550.917 239.461 Z"
              fill="transparent"
              id="curve4"
            />
          </>
        )}

        {phase?.overlayDescriptions?.map((overlay, index) => (
          <text
            key={index}
            alignmentBaseline="middle"
            transform={`translate(0, ${index === 3 ? '7' : '-7'})`}
            className={styles.overlayDescription}
          >
            <textPath
              className="fsdsdfsf"
              xlinkHref={`#curve${index + overlayStart}${isOverlayInverted ? '_inverted' : ''}`}
              fill={phase?.colors?.[3] || '#fff'}
              startOffset="25%"
              textAnchor="middle"
              alignmentBaseline={
                (isOverlayInverted && index !== 3) || (!isOverlayInverted && index === 3) ? 'before-edge' : 'after-edge'
              }
            >
              {overlay.label}
            </textPath>
          </text>
        ))}
      </g>
      <g>
        <foreignObject
          transform={`rotate(${-rotation} 642 93) translate(${textX} ${textY})`}
          height="200"
          width="500"
          x="395"
          y="-140"
          dominantBaseline="central"
          alignmentBaseline="central"
          textAnchor="middle"
          opacity={isActive ? '1' : '1'}
          className={styles.foreignObject}
        >
          <Flex
            className={styles.pointTextWrap}
            justify={textOrientation}
            align={isDescriptionBottom ? 'start' : 'end'}
          >
            <Flex className={styles.pointText} direction="column" align={textOrientation} gap={15}>
              <div
                className={styles.phaseTitleWrap}
                style={{
                  background: isActive ? 'rgba(255,255,255,.3)' : 'transparent',
                  order: isDescriptionBottom ? 0 : 1,
                }}
              >
                <h3
                  className={styles.phaseTitle}
                  style={{
                    color: phase?.colors?.[2] || '#fff',
                  }}
                >
                  {phase.title}
                </h3>
              </div>

              <p
                className={clsx(styles.phaseDescripton, {
                  [styles.phaseDescriptonWide]: isWideDescription,
                })}
                style={{
                  color: phase?.colors?.[2] || '#fff',
                  order: isDescriptionBottom ? 1 : 0,
                  maxWidth: isWideDescription ? '400px' : '300px',
                  textAlign,
                  opacity: isActive ? '1' : '0',
                }}
              >
                {phase.description}
              </p>
            </Flex>
          </Flex>
        </foreignObject>
      </g>
      <circle cx="642" cy="93" r={15} fill={phase?.colors?.[1] || 'white'} />
      <defs>
        <filter id="rounded-corners-2" width="140%" height="190%" x="-20%" y="-45%">
          <feFlood floodColor="#fff" />
          <feGaussianBlur stdDeviation="8" />
          <feComponentTransfer>
            <feFuncA type="table" tableValues="0 0 0 1" />
          </feComponentTransfer>

          <feComponentTransfer>
            <feFuncA type="table" tableValues="0 1 1 1 1 1 1 1" />
          </feComponentTransfer>

          <feComponentTransfer>
            <feFuncA type="linear" slope="0.2" />
          </feComponentTransfer>
          <feComposite operator="over" in="SourceGraphic" />
        </filter>
      </defs>
    </g>
  )
}
