import { WppTypography, WppPopover, WppButton } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useCallback } from 'react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/customProcess/components/allianz/PointPopup.module.scss'
import { IPointPopupConfig } from 'pages/customProcess/components/allianz/types'

export const PointPopup = ({
  config,
  target,
  onClose,
}: {
  config: IPointPopupConfig
  target: any
  onClose: () => void
}) => {
  const targetRect = target?.getBoundingClientRect()

  const getPosition = useCallback(
    (reference: { width: number; height: number; x: number; y: number }): [number, number] => {
      switch (config.placement) {
        case 'left-top':
        case 'left-top-short':
          return [targetRect.x - reference.x + targetRect.width, targetRect.y - reference.y]
        case 'left-bottom':
          return [targetRect.x - reference.x + targetRect.width, targetRect.y - reference.y - reference.height]
        case 'right-top':
          return [targetRect.x - reference.x - reference.width, targetRect.y - reference.y]
        case 'right-bottom':
        case 'right-bottom-short':
          return [targetRect.x - reference.x - reference.width, targetRect.y - reference.y - reference.height]
        default:
          return [targetRect.x - reference.x, targetRect.y - reference.y]
      }
    },
    [targetRect, config.placement],
  )

  return (
    <WppPopover
      config={{
        triggerTarget: target,
        offset: ({ reference, popper }) => getPosition({ ...reference, width: popper.width, height: popper.height }),
        popperOptions: {
          modifiers: [
            {
              name: 'flip',
              enabled: false,
            },
          ],
        },
        onHide: onClose,
      }}
      externalClass={clsx(styles.pointPopup, {
        [styles.leftTop]: config.placement === 'left-top',
        [styles.leftBottom]: config.placement === 'left-bottom',
        [styles.rightTop]: config.placement === 'right-top',
        [styles.rightBottom]: config.placement === 'right-bottom',
        [styles.rightBottomShort]: config.placement === 'right-bottom-short',
        [styles.leftTopShort]: config.placement === 'left-top-short',
      })}
    >
      <WppButton slot="trigger-element" hidden />

      <Flex gap={12} direction="column">
        {config.description && (
          <div>
            <WppTypography type="s-strong" className={styles.title}>
              Stage description
            </WppTypography>
            <WppTypography type="s-body">{config.description}</WppTypography>
          </div>
        )}
        {config.templates && (
          <div>
            <WppTypography type="s-strong" className={styles.title}>
              Templates
            </WppTypography>
            <WppTypography type="s-body">{config.templates}</WppTypography>
          </div>
        )}
        {config.tools && (
          <div>
            <WppTypography type="s-strong" className={styles.title}>
              Tools
            </WppTypography>
            <WppTypography type="s-body" className={styles.toolWrap}>
              {config.tools.map(({ title, logo }, index) => (
                <span className={styles.tool} key={index}>
                  <img src={logo} alt="" />
                  {title}
                  {index + 1 !== config.tools?.length ? ',' : ''}
                </span>
              ))}
            </WppTypography>
          </div>
        )}
        {config.button && (
          <Link to={config.button.url}>
            <WppButton className={styles.button}>{config.button.title}</WppButton>
          </Link>
        )}
      </Flex>
    </WppPopover>
  )
}
