import schemeImg from 'pages/customProcess/components/procterAndGamble/assets/Group 270989589.png'
import { ProcessPandGCard } from 'pages/customProcess/components/procterAndGamble/processPhases/processPandGCard/ProcessPandGCard'

const Audience = () => {
  return (
    <ProcessPandGCard
      title="Audience"
      description="Build the appropriate assets and build an infrastructure to bring our plan to life"
      nextLink="/custom/p&g/allocation"
    >
      <img src={schemeImg} alt="" />
    </ProcessPandGCard>
  )
}

export default Audience
