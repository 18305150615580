import { WppTypography, WppIconChevron, WppIconUser } from '@platform-ui-kit/components-library-react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { IdeationSvg } from 'pages/customProcess/components/unilever/components/ideationPhaseDetails/components/ideationSvg/IdeationSvg'
import styles from 'pages/customProcess/components/unilever/components/ideationPhaseDetails/IdeationPhaseDetails.module.scss'

interface Props {
  triggerRotateSvg?: boolean
}

export const IdeationPhaseDetails = ({ triggerRotateSvg }: Props) => (
  <Flex direction="column" align="center" className={styles.container}>
    <Link to="/custom/ideation" className={styles.link}>
      <Flex align="center" gap={4}>
        <WppTypography className={styles.title}>Ideation</WppTypography>
        <WppIconChevron color="var(--wpp-grey-color-000)" />
      </Flex>
    </Link>
    <WppTypography type="s-body" className={styles.whiteText}>
      Brief to Campaign
    </WppTypography>
    <div className={styles.roles}>
      <Flex justify="center" gap={8} style={{ marginBottom: '5px' }}>
        <Flex gap={4}>
          <WppIconUser color="var(--wpp-grey-color-000)" />
          <WppTypography type="s-body" className={styles.whiteText}>
            Creative
          </WppTypography>
        </Flex>
        <Flex gap={4}>
          <WppIconUser color="var(--wpp-grey-color-000)" />
          <WppTypography type="s-body" className={styles.whiteText}>
            Strategy
          </WppTypography>
        </Flex>
        <Flex gap={4}>
          <WppIconUser color="var(--wpp-grey-color-000)" />
          <WppTypography type="s-body" className={styles.whiteText}>
            Media
          </WppTypography>
        </Flex>
        <Flex gap={4}>
          <WppIconUser color="var(--wpp-grey-color-000)" />
          <WppTypography type="s-body" className={styles.whiteText}>
            Social Media
          </WppTypography>
        </Flex>
      </Flex>
      <Flex justify="center" gap={8} style={{ marginBottom: '5px' }}>
        <Flex gap={4}>
          <WppIconUser color="var(--wpp-grey-color-000)" />
          <WppTypography type="s-body" className={styles.whiteText}>
            Influencer
          </WppTypography>
        </Flex>
        <Flex gap={4}>
          <WppIconUser color="var(--wpp-grey-color-000)" />
          <WppTypography type="s-body" className={styles.whiteText}>
            Production
          </WppTypography>
        </Flex>
        <Flex gap={4}>
          <WppIconUser color="var(--wpp-grey-color-000)" />
          <WppTypography type="s-body" className={styles.whiteText}>
            Creative Technologist
          </WppTypography>
        </Flex>
        <Flex gap={4}>
          <WppIconUser color="var(--wpp-grey-color-000)" />
          <WppTypography type="s-body" className={styles.whiteText}>
            PR
          </WppTypography>
        </Flex>
      </Flex>
      <Flex justify="center" gap={8}>
        <Flex gap={4}>
          <WppIconUser color="var(--wpp-grey-color-000)" />
          <WppTypography type="s-body" className={styles.whiteText}>
            Tech & Media Partners
          </WppTypography>
        </Flex>
        <Flex gap={4}>
          <WppIconUser color="var(--wpp-grey-color-000)" />
          <WppTypography type="s-body" className={styles.whiteText}>
            Commerce-Social/Retail
          </WppTypography>
        </Flex>
      </Flex>
    </div>
    <IdeationSvg triggerRotateSvg={triggerRotateSvg} />
  </Flex>
)
