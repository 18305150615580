import schemeImg from 'pages/customProcess/components/audible/assets/one pagers_identify.png'
import { ProcessCard } from 'pages/customProcess/components/audible/processPhases/processCard/ProcessCard'

const Identify = () => {
  return (
    <ProcessCard
      type="grow"
      title="Identify"
      description="Identify where growth can come from and who needs to be influenced"
      previousLink="/custom/audible/provoke"
      nextLink="/custom/audible/strategize"
    >
      <img src={schemeImg} alt="" />
    </ProcessCard>
  )
}

export default Identify
