import { FileItemType } from '@platform-ui-kit/components-library'
import { MayBeNull, AttachmentMetadataResponse } from '@wpp-open/core'
import * as zod from 'zod'

import { useUploadProcessFilesApi } from 'hooks/attachments/useUploadProcessFilesApi'
import { Process, Templates, ProcessFormConfig } from 'types/process/process'
import { isFile } from 'utils/files'

export const getTemplateColors = () => {}

export const getInitialValues = (data: MayBeNull<Process>): ProcessFormConfig => {
  const getFieldValue = ({ name, size, url }: AttachmentMetadataResponse) => ({ name, size, url: url || '', type: '' })

  return {
    template: data?.template || Templates.Circular1,
    logo: (data?.logo ? [getFieldValue(data?.logo)] : []) as [FileItemType?],
    background: (data?.background ? [getFieldValue(data?.background)] : []) as [FileItemType?],
    colors: data?.colors || [],
    button: {
      title: data?.button?.title,
      url: data?.button?.url,
      colors: data?.button?.colors || [],
      isVisible: data?.button?.isVisible,
    },
    phases:
      data?.phases
        .map(({ overlayDescriptions, ...phase }) => ({
          ...phase,
          overlayDescriptions: overlayDescriptions.map((label, id) => ({ label, id })),
        }))
        .sort(({ order: orderA }, { order: orderB }) => (orderA && orderB ? orderA - orderB : 0)) || [],
  }
}

export const useMapFormValuesToPayload = () => {
  const handleUploadProcessFiles = useUploadProcessFilesApi()

  return async ({ logo, background, phases, ...values }: ProcessFormConfig) => {
    const logoMetadata = isFile(logo?.[0]) ? (await handleUploadProcessFiles([logo?.[0]]))[0] : null
    const backgroundMetadata = isFile(background?.[0]) ? (await handleUploadProcessFiles([background?.[0]]))[0] : null

    return {
      ...values,
      ...(!logo?.[0] || isFile(logo?.[0]) ? { logo: logoMetadata } : {}),
      ...(!background?.[0] || isFile(background?.[0]) ? { background: backgroundMetadata } : {}),
      phases: phases.map(({ processId, overlayDescriptions, ...phase }, index) => ({
        ...phase,
        overlayDescriptions: overlayDescriptions.map(({ label }) => label),
        order: index + 1,
      })),
    }
  }
}

export const getPreviewImageUrl = (value: [FileItemType?]) =>
  isFile(value?.[0]) ? URL.createObjectURL(value?.[0]) : value?.[0]?.url

export const processSchema = zod.object({
  template: zod.string().min(1),
  logo: zod.array(zod.any()).max(1),
  background: zod.array(zod.any()).max(1),
  colors: zod.array(zod.string()),
  button: zod.object({
    title: zod.string().optional().nullable(),
    url: zod.string().optional().nullable(),
    colors: zod.array(zod.string()).optional(),
    isVisible: zod.boolean().optional(),
  }),
  phases: zod.array(
    zod.object({
      title: zod.string().optional().nullable(),
      overlayDescriptions: zod.array(zod.object({ label: zod.string(), id: zod.number() })).optional(),
      description: zod.string().optional().nullable(),
      groupTitle: zod.string().optional().nullable(),
      colors: zod.array(zod.string()).optional(),
      url: zod.string().optional().nullable(),
      innerPageEnabled: zod.boolean().optional().nullable(),
      id: zod.string().optional().nullable(),
    }),
  ),
})

export const paletteTitles: Record<Templates, { widget: string[]; phase: string[] }> = {
  [Templates.Circular1]: {
    widget: ['Background', 'Widget'],
    phase: ['Overlay', 'Point', 'Title, description', 'Overlay text'],
  },
  [Templates.Circular2]: {
    widget: ['Background', 'Widget'],
    phase: ['Overlay', 'Point', 'Title, description', 'Overlay text'],
  },
}
