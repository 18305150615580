import { WppIconArrow } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/customProcess/components/ikea/components/phaseCard/PhaseCard.module.scss'

interface Props {
  isActive: boolean
  index: number
  title: string
  description: string
  link?: string
  onPhaseClick: (index: number) => void
}

export const PhaseCard = ({ isActive, index, title, description, link, onPhaseClick }: Props) => {
  return (
    <div className={clsx(styles.phase, isActive && styles.activePhase)} onClick={() => onPhaseClick(index)}>
      <div className={clsx(styles.activePhaseIndex, !isActive && styles.blackIndex)}>{index + 1}</div>
      <Flex direction="column" justify="end" className={styles.fullheight}>
        {isActive ? (
          <div>
            {link ? (
              <Link to={link} className={styles.link}>
                <h2 className={clsx(styles.activePhaseTitle, styles.activePhaseTitleLink)}>{title}</h2>
              </Link>
            ) : (
              <h2 className={styles.activePhaseTitle}>{title}</h2>
            )}
            <p className={styles.phaseDescription}>{description}</p>
          </div>
        ) : (
          <Flex direction="column" justify="end" className={styles.fullheight}>
            <Flex justify="between" align="end" gap={4} className={styles.fullheight}>
              <div className={styles.phaseTitle}>{title}</div>
              <Flex justify="center" align="center" className={styles.iconButton}>
                <WppIconArrow direction="right" color="var(--wpp-grey-color-000)" />
              </Flex>
            </Flex>
          </Flex>
        )}
      </Flex>
    </div>
  )
}
