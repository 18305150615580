import { MayBeNull } from '@wpp-open/core'
import clsx from 'clsx'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { ReactComponent as BackIcon } from 'pages/customProcess/components/audible/assets/BackIcon.svg'
import logoGif from 'pages/customProcess/components/audible/assets/logoGif.gif'
import styles from 'pages/customProcess/components/audible/AudibleProcess.module.scss'
import { AudibleSvg } from 'pages/customProcess/components/audible/AudibleSvg'
import { Phases, PhasePoints } from 'pages/customProcess/components/audible/types'

const phaseLinks: Record<PhasePoints, string> = {
  [PhasePoints.Provoke]: './audible/provoke',
  [PhasePoints.Identify]: './audible/identify',
  [PhasePoints.Strategize]: './audible/strategize',
  [PhasePoints.Plan]: './audible/plan',
  [PhasePoints.Activate]: './audible/activate',
  [PhasePoints.Deploy]: './audible/deploy',
  [PhasePoints.Report]: './audible/report',
  [PhasePoints.Learn]: './audible/learn',
}

export const AudibleProcess = () => {
  const [hoverPhase, setHoverPhase] = useState<MayBeNull<Phases>>(null)
  const [activePhase, setActivePhase] = useState<MayBeNull<Phases>>(null)
  const [activePoint, setActivePoint] = useState<MayBeNull<PhasePoints>>(null)
  const navigate = useNavigate()

  const handlePhaseTitleClick = useCallback(
    (phase: Phases) => () => {
      setActivePhase(phase)
    },
    [],
  )

  const handlePhaseEnter = useCallback(
    (phase: Phases) => () => {
      setHoverPhase(phase)
    },
    [],
  )

  const handlePhaseLeave = useCallback(() => {
    setHoverPhase(null)
  }, [])

  const handleBackBtnClick = useCallback(() => {
    setActivePhase(null)
  }, [])

  const handlePointEnter = useCallback(
    (parrent: Phases, point: PhasePoints) => () => {
      if (activePhase === parrent) {
        setActivePoint(point)
      }
    },
    [activePhase],
  )

  const handlePointLeave = useCallback(() => {
    setActivePoint(null)
  }, [])

  const handlePointClick = useCallback(
    (parrent: Phases, point: PhasePoints) => () => {
      if (activePoint === point) {
        navigate(phaseLinks[point])
      } else {
        setActivePhase(parrent)
        setActivePoint(point)
      }
    },
    [activePoint, navigate],
  )

  const getIsActivePhaseBg = useCallback(
    (phase: Phases) => {
      return phase === activePhase || phase === hoverPhase
    },
    [activePhase, hoverPhase],
  )

  return (
    <Flex
      align="center"
      justify="center"
      className={clsx(styles.root, {
        [styles.growBg]: getIsActivePhaseBg(Phases.Grow),
        [styles.designBg]: getIsActivePhaseBg(Phases.Design),
        [styles.learnBg]: getIsActivePhaseBg(Phases.Learn),
        [styles.animatedBg]: activePhase,
      })}
    >
      {activePhase && <BackIcon className={styles.backBtn} onClick={handleBackBtnClick} />}
      <AudibleSvg
        onPhaseClick={handlePhaseTitleClick}
        onPhaseEnter={handlePhaseEnter}
        onPhaseLeave={handlePhaseLeave}
        onPointClick={handlePointClick}
        onPointEnter={handlePointEnter}
        onPointLeave={handlePointLeave}
        activePhase={activePhase}
        hoverPhase={hoverPhase}
        activePoint={activePoint}
        className={styles.svg}
        pointClassName={styles.svgPoint}
        phaseClassName={styles.svgPhase}
      />
      {!activePhase && <img src={logoGif} alt="" className={styles.logoGif} />}
    </Flex>
  )
}
