import { WppTypography, WppIconChevron, WppIconUser } from '@platform-ui-kit/components-library-react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { InspirationSvg } from 'pages/customProcess/components/unilever/components/inspirationPhaseDetails/components/inspirationSvg/InspirationSvg'
import styles from 'pages/customProcess/components/unilever/components/inspirationPhaseDetails/InspirationPhaseDetails.module.scss'

interface Props {
  triggerRotateSvg?: boolean
}

export const InspirationPhaseDetails = ({ triggerRotateSvg }: Props) => (
  <Flex direction="column" align="center" className={styles.container}>
    <Link to="/custom/inspiration" className={styles.link}>
      <Flex align="center" gap={4}>
        <WppTypography className={styles.title}>Inspiration</WppTypography>
        <WppIconChevron color="var(--wpp-grey-color-000)" />
      </Flex>
    </Link>
    <WppTypography type="s-body" className={styles.whiteText}>
      Insight to Strategy
    </WppTypography>
    <div className={styles.roles}>
      <Flex gap={8} style={{ marginBottom: '5px' }}>
        <Flex gap={4}>
          <WppIconUser color="var(--wpp-grey-color-000)" />
          <WppTypography type="s-body" className={styles.whiteText}>
            Strategy
          </WppTypography>
        </Flex>
        <Flex gap={4}>
          <WppIconUser color="var(--wpp-grey-color-000)" />
          <WppTypography type="s-body" className={styles.whiteText}>
            Creative
          </WppTypography>
        </Flex>
        <Flex gap={4}>
          <WppIconUser color="var(--wpp-grey-color-000)" />
          <WppTypography type="s-body" className={styles.whiteText}>
            Social Media
          </WppTypography>
        </Flex>
      </Flex>
      <Flex gap={8}>
        <Flex gap={4}>
          <WppIconUser color="var(--wpp-grey-color-000)" />
          <WppTypography type="s-body" className={styles.whiteText}>
            Influencer
          </WppTypography>
        </Flex>
        <Flex gap={4}>
          <WppIconUser color="var(--wpp-grey-color-000)" />
          <WppTypography type="s-body" className={styles.whiteText}>
            Commerce - Social/Retail
          </WppTypography>
        </Flex>
      </Flex>
    </div>
    <InspirationSvg triggerRotateSvg={triggerRotateSvg} />
  </Flex>
)
