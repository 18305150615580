import { WppButton, WppGrid } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { Circular1Svg } from 'pages/processBuilder/templates/circular1/Circular1Svg'
import styles from 'pages/processBuilder/templates/circular1/CircularProcess1.module.scss'
import { ProcessFormConfig } from 'types/process/process'

export const CircularProcess1 = ({ config, isEditMode }: { config: ProcessFormConfig; isEditMode: boolean }) => {
  const [activePhase, setActivePhase] = useState<MayBeNull<number>>(null)

  const handlePhaseEnter = useCallback(
    (phase: number) => () => {
      setActivePhase(phase)
    },
    [],
  )

  const handlePhaseLeave = useCallback(() => {
    setActivePhase(null)
  }, [])

  return (
    <Flex align="center" justify="center" direction="column" className={styles.root}>
      <WppGrid container>
        <Link
          to={config?.button?.url || ''}
          className={styles.projectLink}
          style={{ visibility: config?.button?.isVisible ? 'visible' : 'hidden' }}
        >
          <WppButton
            className={styles.projectButton}
            style={{ background: config?.button?.colors?.[0], color: config?.button?.colors?.[1] }}
          >
            {config?.button?.title}
          </WppButton>
        </Link>
      </WppGrid>
      <Circular1Svg
        onPhaseEnter={handlePhaseEnter}
        onPhaseLeave={handlePhaseLeave}
        activePhase={activePhase}
        className={styles.svg}
        blurClassname={styles.blurBg}
        config={config}
        isEditMode={isEditMode}
      />
    </Flex>
  )
}

export default CircularProcess1
