import { WppActionButton, WppIconChevron, WppTypography, WppButton } from '@platform-ui-kit/components-library-react'
import { ReactNode } from 'react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import unileverLogoSrc from 'pages/customProcess/components/unilever/processPhases/processCard/assets/logo-unilever.svg'
import styles from 'pages/customProcess/components/unilever/processPhases/processCard/ProcessCard.module.scss'
import { routesManager } from 'utils/routesManager'

interface Props {
  children: ReactNode
  titleSvg: string
  description: string
  previousLink?: string
  nextLink?: string
}

export const ProcessCard = ({ children, titleSvg, description, previousLink, nextLink }: Props) => {
  return (
    <div className={styles.root}>
      <Flex direction="column" className={styles.contentDimensions}>
        <Flex justify="between" align="start" className={styles.fullWidth}>
          <div>
            <Link to="/custom">
              <WppActionButton variant="inverted" className={styles.backButton}>
                <WppIconChevron direction="left" slot="icon-start" />
                Back to Process overview
              </WppActionButton>
            </Link>
            {/* <div>
              <WppTypography tag="h1" className={styles.title}>
                {title}
              </WppTypography>
            </div> */}
            <div className={styles.title}>
              <img alt="" src={titleSvg} />
            </div>
            <WppTypography type="s-body" className={styles.description}>
              {description}
            </WppTypography>
          </div>
          <img src={unileverLogoSrc} alt="Unilever" className={styles.logo} />
        </Flex>
        <Flex direction="column" justify="between" className={styles.flexGrow}>
          <Flex className={styles.mainContentContainer}>{children}</Flex>
          <Flex align="center" justify="between" className={styles.bottomControls}>
            <Flex gap={16}>
              <Link to={previousLink ?? ''}>
                <WppActionButton variant="inverted" disabled={!previousLink}>
                  Previous phase <WppIconChevron slot="icon-start" direction="left" />
                </WppActionButton>
              </Link>
              <Link to={nextLink ?? ''}>
                <WppActionButton variant="inverted" disabled={!nextLink}>
                  Next phase <WppIconChevron slot="icon-end" />
                </WppActionButton>
              </Link>
            </Flex>
            <Link to={routesManager.systemUrls.orchestration.root.url}>
              <WppButton>Go to project details</WppButton>
            </Link>
          </Flex>
        </Flex>
      </Flex>
    </div>
  )
}
