import {
  WppButton,
  WppTypography,
  WppActionButton,
  WppIconPlus,
  WppCard,
  WppIconTrash,
  WppAccordion,
} from '@platform-ui-kit/components-library-react'
import { useQueryClient } from '@tanstack/react-query'
import { useOs } from '@wpp-open/react'
import { useMemo, useEffect, useCallback } from 'react'
import { FormProvider, useFieldArray, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useCreateProcessApi } from 'api/process/mutations/useCreateProcessApi'
import { useEditProcessApi } from 'api/process/mutations/useEditProcessApi'
import { useProcessApi } from 'api/process/queries/useFetchProcessApi'
import { Flex } from 'components/common/flex/Flex'
import { FormAutocomplete } from 'components/form/formAutocomplete/FormAutocomplete'
import { FormCheckbox } from 'components/form/formCheckbox/FormCheckbox'
import { FormFileUpload } from 'components/form/formFileUpload/FormFileUpload'
import { FormPalette } from 'components/form/formPalette/FormPalette'
import { FormSelect } from 'components/form/formSelect/FormSelect'
import { FormTextInput } from 'components/form/formTextInput/FormTextInput'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useForm } from 'hooks/form/useForm'
import { useToast } from 'hooks/useToast'
import styles from 'pages/processBuilder/editProcessModal/EditProcessModal.module.scss'
import { useMapFormValuesToPayload, getInitialValues, processSchema, paletteTitles } from 'pages/processBuilder/utils'
import { Templates, FormPhase } from 'types/process/process'
import { NiceModalWrappedProps, createNiceModal } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  onChange: Function
  resetEditState: () => void
}

export const EditProcessModal = ({ isOpen, onClose, onCloseComplete, onChange, resetEditState }: Props) => {
  const { enqueueToast } = useToast()
  const { t } = useTranslation()
  const {
    osContext: { tenant },
  } = useOs()
  const queryClient = useQueryClient()
  const mapFormValuesToPayload = useMapFormValuesToPayload()

  const { data } = useProcessApi({
    params: {
      tenantId: tenant.id,
    },
  })
  const { mutateAsync: handleCreateProcess } = useCreateProcessApi()
  const { mutateAsync: handleEditProcess } = useEditProcessApi()

  const form = useForm({
    defaultValues: getInitialValues(data),
    validationSchema: processSchema,
  })

  const {
    handleSubmit,
    formState: { isSubmitting, isDirty, isValid },
    watch,
    control,
    register,
  } = form

  const onSubmit = handleSubmit(async values => {
    try {
      const process = await mapFormValuesToPayload(values)

      data
        ? await handleEditProcess({
            process,
            processId: data.id,
          })
        : await handleCreateProcess({
            process: {
              ...process,
              tenantId: tenant.id,
            },
          })

      await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.PROCESS] })

      enqueueToast({
        message: t('process.edit_modal.configured_successfully'),
        type: 'success',
      })
      onClose()
    } catch (e) {
      enqueueToast({
        message: t('process.edit_modal.configured_successfully'),
        type: 'error',
      })
    }
  })

  const handleCloseComplete = useCallback(() => {
    onCloseComplete()
    resetEditState()
  }, [onCloseComplete, resetEditState])

  const templateOptions = useMemo(() => Object.values(Templates).map(value => ({ value, label: value })), [])

  const formData = watch()

  const {
    fields: phases,
    append: appendPhase,
    update: updatePhase,
    remove: removePhase,
  } = useFieldArray({
    control,
    name: 'phases',
    keyName: 'formId',
  })

  useEffect(() => {
    onChange(formData)
  }, [formData, onChange])

  const handleAddPhase = useCallback(() => {
    appendPhase({
      title: 'Phase title',
      description: 'Description',
      overlayDescriptions: [{ label: 'Overlay 1', id: 0 }],
      colors: [],
      parentId: '',
      groupTitle: '',
      url: '',
      children: [],
      innerPageEnabled: false,
    })
  }, [appendPhase])

  const handleRemovePhase = useCallback(
    (index: number) => () => {
      removePhase(index)
    },
    [removePhase],
  )

  const addNewOverlayTitle = useCallback(
    (index: number) => (e: any) => {
      const phase = formData?.phases?.[index] as FormPhase

      updatePhase(index, {
        ...phase,
        overlayDescriptions: [...phase.overlayDescriptions, { label: e.detail, id: phase.overlayDescriptions.length }],
      })
    },
    [formData?.phases, updatePhase],
  )

  return (
    <FormProvider {...form}>
      <SideModal
        formConfig={{ onSubmit }}
        size="m"
        disableOutsideClick
        open={isOpen}
        onWppSideModalClose={onClose}
        onWppSideModalCloseComplete={handleCloseComplete}
        className={styles.modal}
      >
        <WppTypography slot="header" type="2xl-heading">
          {t('process.edit_modal.edit_process')}
        </WppTypography>

        <Flex slot="body" direction="column" gap={28} className={styles.modalBody}>
          <FormSelect
            labelConfig={{ text: t('process.edit_modal.template') }}
            required
            withSearch
            placeholder={t('process.edit_modal.choose_template')}
            name="template"
            options={templateOptions}
            defaultValue={Templates.Circular1}
            disabled
          />

          <FormFileUpload
            name="logo"
            size={1}
            acceptConfig={{
              'image/svg+xml': ['.svg'],
              'image/png': ['.png'],
              'image/jpeg': ['.jpg', '.jpeg'],
              'image/gif': ['.gif'],
            }}
            maxLabelLength={45}
            maxFiles={1}
            className={styles.fileInput}
            label={t('process.edit_modal.choose_logo')}
          />

          <FormFileUpload
            name="background"
            size={1}
            acceptConfig={{
              'image/svg+xml': ['.svg'],
              'image/png': ['.png'],
              'image/jpeg': ['.jpg', '.jpeg'],
              'image/gif': ['.gif'],
            }}
            maxLabelLength={45}
            maxFiles={1}
            className={styles.fileInput}
            label={t('process.edit_modal.choose_background')}
          />

          <FormPalette name="colors" titles={paletteTitles[formData?.template as Templates].widget} />

          <WppCard>
            <Flex direction="column" gap={10}>
              <WppTypography type="s-strong" className={styles.cardTitle}>
                {t('process.edit_modal.button')}
              </WppTypography>
              <Flex direction="row" justify="between" align="end" gap={15}>
                <FormTextInput
                  name="button.title"
                  placeholder={t('process.edit_modal.title')}
                  className={styles.inputHalfRow}
                />
                <FormTextInput
                  name="button.url"
                  placeholder={t('process.edit_modal.link')}
                  className={styles.inputHalfRow}
                />
              </Flex>
              <FormPalette name="button.colors" titles={['Background', 'Text']} />
              <FormCheckbox
                name="button.isVisible"
                labelConfig={{
                  text: t('process.edit_modal.visibility'),
                }}
              />
            </Flex>
          </WppCard>

          <Flex direction="column" gap={20}>
            {phases.map((item, index) => (
              <WppCard key={item.formId}>
                <WppAccordion withDivider={false} className={styles.phaseAccordion}>
                  <WppTypography type="s-strong" slot="header">
                    {t('process.edit_modal.phase_number', { number: index + 1 })}
                  </WppTypography>
                  <WppActionButton
                    className={styles.removePhaseBtn}
                    variant="primary"
                    onClick={handleRemovePhase(index)}
                    slot="actions"
                  >
                    <WppIconTrash slot="icon-start" />
                  </WppActionButton>

                  <Flex direction="column" gap={10} className={styles.phaseFieldsWrap}>
                    <Controller
                      render={({ field }) => (
                        <FormTextInput
                          {...field}
                          name={`phases.${index}.title`}
                          placeholder={t('process.edit_modal.title')}
                        />
                      )}
                      name={`phases.${index}.title`}
                      control={control}
                    />

                    <Controller
                      render={({ field }) => (
                        <FormTextInput
                          {...field}
                          name={`phases.${index}.description`}
                          placeholder={t('process.edit_modal.description')}
                        />
                      )}
                      name={`phases.${index}.description`}
                      control={control}
                    />

                    <FormAutocomplete
                      showCreateNewElement
                      options={[]}
                      multiple
                      onWppCreateNewOption={addNewOverlayTitle(index)}
                      simpleSearch
                      {...register(`phases.${index}.overlayDescriptions`)}
                      defaultValue={[]}
                      placeholder={t('process.edit_modal.overlays')}
                      className={styles.phaseAutocomplete}
                    />

                    <Flex direction="row" justify="between" align="center" gap={15}>
                      <FormPalette
                        {...register(`phases.${index}.colors`)}
                        titles={paletteTitles[formData?.template as Templates].phase}
                        className={styles.inputHalfRow}
                      />

                      <FormCheckbox
                        {...register(`phases.${index}.innerPageEnabled`)}
                        labelConfig={{
                          text: t('process.edit_modal.enable_inner_page'),
                        }}
                        className={styles.inputHalfRow}
                      />
                    </Flex>

                    <Flex direction="row" justify="between" align="center" gap={15}>
                      <Controller
                        render={({ field }) => (
                          <FormTextInput
                            {...field}
                            name={`phases.${index}.groupTitle`}
                            placeholder={t('process.edit_modal.group_title')}
                            className={styles.inputHalfRow}
                          />
                        )}
                        name={`phases.${index}.groupTitle`}
                        control={control}
                      />

                      <Controller
                        render={({ field }) => (
                          <FormTextInput
                            {...field}
                            name={`phases.${index}.url`}
                            placeholder={t('process.edit_modal.custom_link')}
                            className={styles.inputHalfRow}
                            disabled={formData.phases?.[index].innerPageEnabled}
                          />
                        )}
                        name={`phases.${index}.url`}
                        control={control}
                      />
                    </Flex>
                  </Flex>
                </WppAccordion>
              </WppCard>
            ))}

            <WppActionButton onClick={handleAddPhase}>
              <WppIconPlus slot="icon-start" />
              {t('process.edit_modal.add_phase')}
            </WppActionButton>
          </Flex>
        </Flex>

        <Flex slot="actions" gap={12} justify="end">
          <WppButton variant="secondary" onClick={onClose}>
            {t('common.cancel')}
          </WppButton>
          <WppButton variant="primary" type="submit" disabled={(data && !isDirty) || !isValid} loading={isSubmitting}>
            {t('common.save')}
          </WppButton>
        </Flex>
      </SideModal>
    </FormProvider>
  )
}

export const { showModal: showEditProcessModal, useModal: useEditProcessModal } = createNiceModal<Props>(
  EditProcessModal,
  'edit-process-modal',
)
