// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kv8G5{right:0;left:auto}.kv8G5::part(overlay){opacity:0}.kv8G5::part(side-modal){border-left:1px solid var(--wpp-grey-color-200)}.cUvxY{padding-bottom:20px}.sarKp::part(card){overflow:visible}.Vj9NY::part(card){background-color:var(--wpp-grey-color-100);cursor:no-drop}.KNuld::part(content){margin-left:0}.Xl4Rr{position:relative;top:-5px}.Xl4Rr::part(overlay){border-radius:100%}.t7Itc::part(file-upload-container){height:55px}.t7Itc::part(icon-file){display:none}.uMrik{width:50%}.C12uc{z-index:0}", "",{"version":3,"sources":["webpack://./src/pages/phaseBuilder/editPhaseModal/EditPhaseModal.module.scss"],"names":[],"mappings":"AAAA,OACE,OAAA,CACA,SAAA,CAEA,sBACE,SAAA,CAGF,yBACE,+CAAA,CAIJ,OACE,mBAAA,CAIA,mBACE,gBAAA,CAKF,mBACE,0CAAA,CACA,cAAA,CAKF,sBACE,aAAA,CAIJ,OACE,iBAAA,CACA,QAAA,CAEA,sBACE,kBAAA,CAKF,oCACE,WAAA,CAGF,wBACE,YAAA,CAIJ,OACE,SAAA,CAGF,OACE,SAAA","sourcesContent":[".modal {\n  right: 0;\n  left: auto;\n\n  &::part(overlay) {\n    opacity: 0;\n  }\n\n  &::part(side-modal) {\n    border-left: 1px solid var(--wpp-grey-color-200);\n  }\n}\n\n.modalBody {\n  padding-bottom: 20px;\n}\n\n.column {\n  &::part(card) {\n    overflow: visible;\n  }\n}\n\n.inactiveColumn {\n  &::part(card) {\n    background-color: var(--wpp-grey-color-100);\n    cursor: no-drop;\n  }\n}\n\n.columnAccordion {\n  &::part(content) {\n    margin-left: 0;\n  }\n}\n\n.removeColumnBtn {\n  position: relative;\n  top: -5px;\n\n  &::part(overlay) {\n    border-radius: 100%;\n  }\n}\n\n.fileInput {\n  &::part(file-upload-container) {\n    height: 55px;\n  }\n\n  &::part(icon-file) {\n    display: none;\n  }\n}\n\n.inputHalfRow {\n  width: 50%;\n}\n\n.addColumnBtn {\n  z-index: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "kv8G5",
	"modalBody": "cUvxY",
	"column": "sarKp",
	"inactiveColumn": "Vj9NY",
	"columnAccordion": "KNuld",
	"removeColumnBtn": "Xl4Rr",
	"fileInput": "t7Itc",
	"inputHalfRow": "uMrik",
	"addColumnBtn": "C12uc"
};
export default ___CSS_LOADER_EXPORT___;
