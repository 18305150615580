import { useOs } from '@wpp-open/react'
import { Navigate } from 'react-router-dom'

import { TigerBrandsProcess } from 'pages/customProcess//components/tigerBrands/TigerBrandsProcess'
import { AldiProcess } from 'pages/customProcess/components/aldi/AldiProcess'
import { AllianzProcess } from 'pages/customProcess/components/allianz/AllianzProcess'
import { ASMLProcess } from 'pages/customProcess/components/asml/ASMLProcess'
import { AudibleProcess } from 'pages/customProcess/components/audible/AudibleProcess'
import { AxaProcess } from 'pages/customProcess/components/axa/AxaProcess'
import { BeiersdorfProcess } from 'pages/customProcess/components/beiersdorf/BeiersdorfProcess'
import { BootsProcess } from 'pages/customProcess/components/boots/BootsProcess'
import { CocaColaProcess } from 'pages/customProcess/components/cocaCola/CocaColaProcess'
import { EasyJetProcess } from 'pages/customProcess/components/easyjet/EasyJetProcess'
import { FerreroProcess } from 'pages/customProcess/components/ferreroNew/FerreroProcess'
import { FordMotorChinaProcess } from 'pages/customProcess/components/fordMotorChina/FordMotorChinaProcess'
import { FossilProcess } from 'pages/customProcess/components/fossilNew/FossilProcess'
import { GoogleProcess } from 'pages/customProcess/components/google/GoogleProcess'
import { HenkelProcess } from 'pages/customProcess/components/henkel/HenkelProcess'
import { IbmProcess } from 'pages/customProcess/components/ibm/IbmProcess'
import { IkeaProcess } from 'pages/customProcess/components/ikea/IkeaProcess'
import { LoccitaneProcess } from 'pages/customProcess/components/loccitane/LoccitaneProcess'
import { LorealProcess } from 'pages/customProcess/components/loreal/LorealProcess'
import { LvmhProcess } from 'pages/customProcess/components/lvmh/LvmhProcess'
import { MattrassProcess } from 'pages/customProcess/components/mattrass/MattrassProcess'
import { NestleProcess } from 'pages/customProcess/components/nestle/NestleProcess'
import { NestleAsiaProcess } from 'pages/customProcess/components/nestleAsia/NestleProcess'
import { NestleChinaProcess } from 'pages/customProcess/components/nestleChina/NestleChinaProcess'
import { NestleHealthProcess } from 'pages/customProcess/components/nestleHealth/NestleHealthProcess'
import { NetflixProcess } from 'pages/customProcess/components/netflix/NetflixProcess'
import { No7BeautyProcess } from 'pages/customProcess/components/no7Beauty/No7BeautyProcess'
import { OrangeProcess } from 'pages/customProcess/components/orange/OrangeProcess'
import { PaneraProcess } from 'pages/customProcess/components/panera/PaneraProcess'
import { PerrigoProcess } from 'pages/customProcess/components/perrigo/PerrigoProcess'
import { PfizerProcess } from 'pages/customProcess/components/pfizer/PfizerProcess'
import { ProcterAndGambleProcess } from 'pages/customProcess/components/procterAndGamble/ProcterAndGambleProcess'
import { SEINProcess } from 'pages/customProcess/components/sein/SEINProcess'
import { SpotifyProcess } from 'pages/customProcess/components/spotify/SpotifyProcess'
import { TapestryProcess } from 'pages/customProcess/components/tapestry/TapestryProcess'
import { UnileverProcess } from 'pages/customProcess/components/unilever/UnileverProcess'
import { VodafoneProcess } from 'pages/customProcess/components/vodafone/VodafoneProcess'
import { WarnerBrosProcess } from 'pages/customProcess/components/warnerBros/WarnerBrosProcess'
import { Tenants } from 'pages/customProcess/utils/tenants'
import { routesManager } from 'utils/routesManager'

export const ProcessCase = () => {
  const {
    osContext: { tenant },
  } = useOs()

  switch (tenant.name) {
    case Tenants.Audible:
      return <AudibleProcess />
    case Tenants.Pfizer:
      return <PfizerProcess />
    case Tenants.EasyJet:
      return <EasyJetProcess />
    case Tenants.Fossil:
      return <FossilProcess />
    case Tenants.No7Beauty:
      return <No7BeautyProcess />
    case Tenants.Nestle:
    case Tenants.NestleUK:
    case Tenants.NestleMEAP:
      return <NestleProcess />
    case Tenants.NestleChina:
      return <NestleChinaProcess />
    case Tenants.NestleAsia:
      return <NestleAsiaProcess />
    case Tenants.NestleAOA:
      return <NestleAsiaProcess />
    case Tenants.NestleHealthScience:
      return <NestleHealthProcess />
    case Tenants.Beiersdorf:
      return <BeiersdorfProcess />
    case Tenants.AXA:
      return <AxaProcess />
    case Tenants.Orange:
      return <OrangeProcess />
    case Tenants.Aldi:
      return <AldiProcess />
    case Tenants.Perrigo:
      return <PerrigoProcess />
    case Tenants.Panera:
      return <PaneraProcess />
    case Tenants.Allianz:
      return <AllianzProcess />
    case Tenants.Spotify:
      return <SpotifyProcess />
    case Tenants.Tapestry:
      return <TapestryProcess />
    case Tenants.Unilever:
      return <UnileverProcess />
    case Tenants.Google:
      return <GoogleProcess />
    case Tenants.Mattressfirm:
      return <MattrassProcess />
    case Tenants.IBM:
      return <IbmProcess />
    case Tenants.Ikea:
      return <IkeaProcess />
    case Tenants.Vodafone:
      return <VodafoneProcess />
    case Tenants.Boots:
      return <BootsProcess />
    case Tenants.TCCC:
      return <CocaColaProcess />
    case Tenants.Ferrero:
      return <FerreroProcess />
    case Tenants.WarnerBrothers:
      return <WarnerBrosProcess />
    case Tenants.LVMH:
      return <LvmhProcess />
    case Tenants.Samsung:
      return <SEINProcess />
    case Tenants.Henkel:
      return <HenkelProcess />
    case Tenants.ProcterAndGamble:
      return <ProcterAndGambleProcess />
    case Tenants.ASML:
      return <ASMLProcess />
    case Tenants.Netflix:
      return <NetflixProcess />
    case Tenants.Loccitane:
      return <LoccitaneProcess />
    case Tenants.FordMotorChina:
      return <FordMotorChinaProcess />
    case Tenants.TigerBrands:
      return <TigerBrandsProcess />
    case Tenants.Loreal:
      return <LorealProcess />
    default:
      return <Navigate to={routesManager.notFound.url} />
  }
}
