import { MayBeNull } from '@wpp-open/core'
import { useCallback, useState, useRef } from 'react'

import { Flex } from 'components/common/flex/Flex'
import { AllianzSvg } from 'pages/customProcess/components/allianz/AlliansSvg'
import styles from 'pages/customProcess/components/allianz/AllianzProcess.module.scss'
import { PointPopup } from 'pages/customProcess/components/allianz/PointPopup'
import { pointPopupsConfig } from 'pages/customProcess/components/allianz/pointPopupsConfig'
import { Phases, PhasePoints } from 'pages/customProcess/components/allianz/types'

export const AllianzProcess = () => {
  const [activePhase, setActivePhase] = useState<MayBeNull<Phases>>(null)
  const [activePoint, setActivePoint] = useState<MayBeNull<PhasePoints>>(null)
  const pointRefs = useRef<Record<PhasePoints, any>>({
    [PhasePoints.Adapt]: null,
    [PhasePoints.Ambition]: null,
    [PhasePoints.Audience]: null,
    [PhasePoints.Approach]: null,
    [PhasePoints.Activate]: null,
    [PhasePoints.Assets]: null,
  })

  const handlePhaseEnter = useCallback(
    (phase: Phases) => () => {
      !activePoint && setActivePhase(phase)
    },
    [activePoint],
  )

  const handlePhaseLeave = useCallback(() => {
    !activePoint && setActivePhase(null)
  }, [activePoint])

  const handlePointClick = useCallback(
    (point: PhasePoints) => () => {
      setActivePoint(point)
      setActivePhase(pointPopupsConfig[point].parrent)
    },
    [],
  )

  const handlePopupClose = useCallback(() => {
    setActivePoint(null)
    setActivePhase(null)
  }, [])

  return (
    <Flex align="center" justify="center" className={styles.root}>
      <AllianzSvg
        onPhaseEnter={handlePhaseEnter}
        onPhaseLeave={handlePhaseLeave}
        onPointClick={handlePointClick}
        activePhase={activePhase}
        activePoint={activePoint}
        pointRefs={pointRefs}
        className={styles.svg}
        pointClassName={styles.svgPoint}
      />
      {Object.keys(PhasePoints).map((key: string) => (
        <PointPopup
          config={pointPopupsConfig[key as PhasePoints]}
          target={pointRefs.current[key as PhasePoints]}
          key={key}
          onClose={handlePopupClose}
        />
      ))}
    </Flex>
  )
}
