import { Route, Routes } from 'react-router-dom'

import Approach from 'pages/customProcess/components/allianz/approach/Approach'
import AssetsIdeas from 'pages/customProcess/components/allianz/assetsIdeas/AssetsIdeas'
import { ASMLRoutes } from 'pages/customProcess/components/asml/ASMLRoutes'
import AudibleActivate from 'pages/customProcess/components/audible/processPhases/pages/activate/Activate'
import AudibleDeploy from 'pages/customProcess/components/audible/processPhases/pages/deploy/Deploy'
import AudibleIdentify from 'pages/customProcess/components/audible/processPhases/pages/identify/Identify'
import AudibleLearn from 'pages/customProcess/components/audible/processPhases/pages/learn/Learn'
import AudiblePlan from 'pages/customProcess/components/audible/processPhases/pages/plan/Plan'
import AudibleProvoke from 'pages/customProcess/components/audible/processPhases/pages/provoke/Provoke'
import AudibleReport from 'pages/customProcess/components/audible/processPhases/pages/report/Report'
import AudibleStrategize from 'pages/customProcess/components/audible/processPhases/pages/strategize/Strategize'
import Activation from 'pages/customProcess/components/boots/processPhases/activation/Activation'
import ConnectionPlanning from 'pages/customProcess/components/boots/processPhases/connectionPlanning/ConnectionPlanning'
import Evaluation from 'pages/customProcess/components/boots/processPhases/evaluation/Evaluation'
import ProjectKickOff from 'pages/customProcess/components/boots/processPhases/projectKickOff/ProjectKickOff'
import StrategicResponse from 'pages/customProcess/components/boots/processPhases/strategicResponse/StrategicResponse'
import { FerreroRoutes } from 'pages/customProcess/components/ferreroNew/FerreroRoutes'
import { FossilRoutes } from 'pages/customProcess/components/fossilNew/FossilRoutes'
import { Steps } from 'pages/customProcess/components/google/components/steps/Steps'
import { HenkelRoutes } from 'pages/customProcess/components/henkel/HenkelRoutes'
import AdaptIbm from 'pages/customProcess/components/ibm/processPhases/pages/adapt/Adapt'
import DecisionIbm from 'pages/customProcess/components/ibm/processPhases/pages/decision/Decision'
import DeliverIbm from 'pages/customProcess/components/ibm/processPhases/pages/deliver/Deliver'
import DesignIbm from 'pages/customProcess/components/ibm/processPhases/pages/design/Design'
import DiscoverIbm from 'pages/customProcess/components/ibm/processPhases/pages/discover/Discover'
import StrategyMeasurementSolutions from 'pages/customProcess/components/ikea/processPhases/pages/strategyMeasurementSolutions/StrategyMeasurementSolutions'
import { LorealRoutes } from 'pages/customProcess/components/loreal/LorealRoutes'
import DeliverLvmh from 'pages/customProcess/components/lvmh/processPhases/deliverLvmh/DeliverLvmh'
import DesignLvmh from 'pages/customProcess/components/lvmh/processPhases/designLvmh/DesignLvmh'
import DiscoverLvmh from 'pages/customProcess/components/lvmh/processPhases/discoverLvmh/DiscoverLvmh'
import BigIdeaDevelopment from 'pages/customProcess/components/nestle/processPhases/pages/bigIdeaDevelopment/BigIdeaDevelopment'
import CampaignBrief from 'pages/customProcess/components/nestle/processPhases/pages/campaignBrief/CampaignBrief'
import CampaignBudgetSetting from 'pages/customProcess/components/nestle/processPhases/pages/campaignBudgetSetting/CampaignBudgetSetting'
import CampaignStrategy from 'pages/customProcess/components/nestle/processPhases/pages/campaignStrategy/CampaignStrategy'
import CampaignAnalysis from 'pages/customProcess/components/nestle/processPhases/pages/campaingAnalysis/CampaingAnalysis'
import MediaActivation from 'pages/customProcess/components/nestle/processPhases/pages/mediaActivation/MediaActivation'
import MediaPlan from 'pages/customProcess/components/nestle/processPhases/pages/mediaPlan/MediaPlan'
import PortfolioAllocationAndCommunication from 'pages/customProcess/components/nestle/processPhases/pages/portfolioAllocationAndCommunication/PortfolioAllocationAndCommunication'
import StrategicMediaPlanning from 'pages/customProcess/components/nestle/processPhases/pages/strategicMediaPlanning/StrategicMediaPlanning'
import ActivationPlanning from 'pages/customProcess/components/nestleAsia/processPhases/pages/activationPlanning/ActivationPlanning'
import BigBriefIdea from 'pages/customProcess/components/nestleAsia/processPhases/pages/bigBriefIdea/BigBriefIdea'
import CampaignDiscovery from 'pages/customProcess/components/nestleAsia/processPhases/pages/campaignDiscovery/CampaignDiscovery'
import CampaignStrategicApproach from 'pages/customProcess/components/nestleAsia/processPhases/pages/campaignStrategicApproach/CampaignStrategicApproach'
import ContactPointsAndInvestmentPlanning from 'pages/customProcess/components/nestleAsia/processPhases/pages/contactPointsAndInvestmentPlanning/ContactPointsAndInvestmentPlanning'
import Implementation from 'pages/customProcess/components/nestleAsia/processPhases/pages/implementation/Implementation'
import IntegratedCommunicationsBrief from 'pages/customProcess/components/nestleAsia/processPhases/pages/integratedCommunicationsBrief/IntegratedCommunicationsBrief'
import OutcomesAndAdapt from 'pages/customProcess/components/nestleAsia/processPhases/pages/outcomesAndAdapt/OutcomesAndAdapt'
import PrerequisitesToBEP3 from 'pages/customProcess/components/nestleAsia/processPhases/pages/prerequisitesToBEP3/PrerequisitesToBEP3'
import BigIdeaDevelopmentCh from 'pages/customProcess/components/nestleChina/processPhases/pages/bigIdeaDevelopment/BigIdeaDevelopment'
import CampaignBriefCh from 'pages/customProcess/components/nestleChina/processPhases/pages/campaignBrief/CampaignBrief'
import CampaignBudgetSettingCh from 'pages/customProcess/components/nestleChina/processPhases/pages/campaignBudgetSetting/CampaignBudgetSetting'
import CampaignStrategyCh from 'pages/customProcess/components/nestleChina/processPhases/pages/campaignStrategy/CampaignStrategy'
import CampaignAnalysisCh from 'pages/customProcess/components/nestleChina/processPhases/pages/campaingAnalysis/CampaingAnalysis'
import MediaActivationCh from 'pages/customProcess/components/nestleChina/processPhases/pages/mediaActivation/MediaActivation'
import MediaPlanCh from 'pages/customProcess/components/nestleChina/processPhases/pages/mediaPlan/MediaPlan'
import PortfolioAllocationAndCommunicationCh from 'pages/customProcess/components/nestleChina/processPhases/pages/portfolioAllocationAndCommunication/PortfolioAllocationAndCommunication'
import StrategicMediaPlanningCh from 'pages/customProcess/components/nestleChina/processPhases/pages/strategicMediaPlanning/StrategicMediaPlanning'
import { NestleHealthRoutes } from 'pages/customProcess/components/nestleHealth/NestleHealthRoutes'
import { SignalsPage } from 'pages/customProcess/components/netflix/pages/signalsPage/SignalsPage'
import { SprinboardPage } from 'pages/customProcess/components/netflix/pages/springboardPage/SpringboardPage'
import { StrategyPage } from 'pages/customProcess/components/netflix/pages/strategyPage/StrategyPage'
import { StreamingPage } from 'pages/customProcess/components/netflix/pages/streamingPage/StreamingPage'
import { UpPage } from 'pages/customProcess/components/netflix/pages/upPage/UpPage'
import N7Activation from 'pages/customProcess/components/no7Beauty/processPhases/activation/Activation'
import N7ConnectionPlanning from 'pages/customProcess/components/no7Beauty/processPhases/connectionPlanning/ConnectionPlanning'
import N7Evaluation from 'pages/customProcess/components/no7Beauty/processPhases/evaluation/Evaluation'
import N7ProjectKickOff from 'pages/customProcess/components/no7Beauty/processPhases/projectKickOff/ProjectKickOff'
import N7StrategicResponse from 'pages/customProcess/components/no7Beauty/processPhases/strategicResponse/StrategicResponse'
import BusinessCaseProcess from 'pages/customProcess/components/pfizer/businessCaseProcess/BusinessCaseProcess'
import IdeasCaseProcess from 'pages/customProcess/components/pfizer/ideasCaseProcess/IdeasCaseProcess'
import MediaCaseProcess from 'pages/customProcess/components/pfizer/mediaCaseProcess/MediaCaseProcess'
import OptimisationCaseProcess from 'pages/customProcess/components/pfizer/optimisationCaseProcess/OptimisationCaseProcess'
import ActivationPandG from 'pages/customProcess/components/procterAndGamble/processPhases/pages/activation/Activation'
import AllocationPandG from 'pages/customProcess/components/procterAndGamble/processPhases/pages/allocation/Allocation'
import AttributionPandG from 'pages/customProcess/components/procterAndGamble/processPhases/pages/attribution/Attribution'
import AudiencePandG from 'pages/customProcess/components/procterAndGamble/processPhases/pages/audience/Audience'
import DeliverSein from 'pages/customProcess/components/sein/processPhases/DeliverSein'
import DesignSein from 'pages/customProcess/components/sein/processPhases/DesignSein'
import DiscoverSein from 'pages/customProcess/components/sein/processPhases/DiscoverSein'
import Adapt from 'pages/customProcess/components/spotify/processPhases/pages/adapt/Adapt'
import Decision from 'pages/customProcess/components/spotify/processPhases/pages/decision/Decision'
import Deliver from 'pages/customProcess/components/spotify/processPhases/pages/deliver/Deliver'
import Design from 'pages/customProcess/components/spotify/processPhases/pages/design/Design'
import Discover from 'pages/customProcess/components/spotify/processPhases/pages/discover/Discover'
import { TigerBrandsRoutes } from 'pages/customProcess/components/tigerBrands/TigerBrandsRoutes'
import Ideation from 'pages/customProcess/components/unilever/processPhases/pages/ideation/Ideation'
import Impact from 'pages/customProcess/components/unilever/processPhases/pages/impact/Impact'
import Inspiration from 'pages/customProcess/components/unilever/processPhases/pages/inspiration/Inspiration'
import { ProcessCase } from 'pages/customProcess/ProcessCase'
import { CocaColaProcess } from 'pages/customWidget/cocaCola/CocaColaProcess'

export const CustomProcessRoutes = () => {
  return (
    <Routes>
      <Route index element={<ProcessCase />} />
      <Route path="business" element={<BusinessCaseProcess />} />
      <Route path="media" element={<MediaCaseProcess />} />
      <Route path="ideas" element={<IdeasCaseProcess />} />
      <Route path="optimisation" element={<OptimisationCaseProcess />} />
      <Route path="big-idea-development" element={<BigIdeaDevelopment />} />
      <Route path="portfolio-allocation-communication-strategy" element={<PortfolioAllocationAndCommunication />} />
      <Route path="campaign-brief" element={<CampaignBrief />} />
      <Route path="campaign-strategy" element={<CampaignStrategy />} />
      <Route path="campaign-budget-setting" element={<CampaignBudgetSetting />} />
      <Route path="strategic-media-planning" element={<StrategicMediaPlanning />} />
      <Route path="media-plan" element={<MediaPlan />} />
      <Route path="media-activation" element={<MediaActivation />} />
      <Route path="campaign-analysis" element={<CampaignAnalysis />} />
      <Route path="approach" element={<Approach />} />
      <Route path="assets-ideas" element={<AssetsIdeas />} />
      <Route path="discover" element={<Discover />} />
      <Route path="decision" element={<Decision />} />
      <Route path="design" element={<Design />} />
      <Route path="deliver" element={<Deliver />} />
      <Route path="adapt" element={<Adapt />} />
      <Route path="inspiration" element={<Inspiration />} />
      <Route path="ideation" element={<Ideation />} />
      <Route path="impact" element={<Impact />} />
      <Route path="pre-discover" element={<Steps />} />
      <Route path="strategy-measurement-solutions" element={<StrategyMeasurementSolutions />} />
      <Route path="p&g">
        <Route path="allocation" element={<AllocationPandG />} />
        <Route path="activation" element={<ActivationPandG />} />
        <Route path="attribution" element={<AttributionPandG />} />
        <Route path="audience" element={<AudiencePandG />} />
      </Route>
      <Route path="ch">
        <Route path="big-idea-development" element={<BigIdeaDevelopmentCh />} />
        <Route path="campaign-brief" element={<CampaignBriefCh />} />
        <Route path="campaign-budget-setting" element={<CampaignBudgetSettingCh />} />
        <Route path="campaign-strategy" element={<CampaignStrategyCh />} />
        <Route path="campaign-analysis" element={<CampaignAnalysisCh />} />
        <Route path="media-activation" element={<MediaActivationCh />} />
        <Route path="media-plan" element={<MediaPlanCh />} />
        <Route path="portfolio-allocation-communication-strategy" element={<PortfolioAllocationAndCommunicationCh />} />
        <Route path="strategic-media-planning" element={<StrategicMediaPlanningCh />} />
      </Route>
      <Route path="ibm">
        <Route path="discover" element={<DiscoverIbm />} />
        <Route path="decision" element={<DecisionIbm />} />
        <Route path="design" element={<DesignIbm />} />
        <Route path="deliver" element={<DeliverIbm />} />
        <Route path="adapt" element={<AdaptIbm />} />
      </Route>
      <Route path="project-kick-off" element={<ProjectKickOff />} />
      <Route path="strategic-response" element={<StrategicResponse />} />
      <Route path="connection-planning" element={<ConnectionPlanning />} />
      <Route path="activation" element={<Activation />} />
      <Route path="evaluation" element={<Evaluation />} />
      <Route path="n7">
        <Route path="project-kick-off" element={<N7ProjectKickOff />} />
        <Route path="strategic-response" element={<N7StrategicResponse />} />
        <Route path="creative-development" element={<N7ConnectionPlanning />} />
        <Route path="activation" element={<N7Activation />} />
        <Route path="evaluation" element={<N7Evaluation />} />
      </Route>
      <Route path="netflix">
        <Route path="up" element={<UpPage />} />
        <Route path="springboard" element={<SprinboardPage />} />
        <Route path="strategy" element={<StrategyPage />} />
        <Route path="streaming" element={<StreamingPage />} />
        <Route path="signals" element={<SignalsPage />} />
      </Route>
      <Route path="big-brief-idea" element={<BigBriefIdea />} />
      <Route path="prerequisites-to-bep-3" element={<PrerequisitesToBEP3 />} />
      <Route path="integrated-communications-brief" element={<IntegratedCommunicationsBrief />} />
      <Route path="campaign-discovery" element={<CampaignDiscovery />} />
      <Route path="campaign-strategic-approach" element={<CampaignStrategicApproach />} />
      <Route path="contact-points-and-investment-planning" element={<ContactPointsAndInvestmentPlanning />} />
      <Route path="activation-planning" element={<ActivationPlanning />} />
      <Route path="implementation" element={<Implementation />} />
      <Route path="outcomes-and-adapt" element={<OutcomesAndAdapt />} />
      <Route path="lvmh">
        <Route path="discover" element={<DiscoverLvmh />} />
        <Route path="design" element={<DesignLvmh />} />
        <Route path="deliver" element={<DeliverLvmh />} />
      </Route>
      <Route path="sein">
        <Route path="discover" element={<DiscoverSein />} />
        <Route path="design" element={<DesignSein />} />
        <Route path="deliver" element={<DeliverSein />} />
      </Route>
      <Route path="audible">
        <Route path="provoke" element={<AudibleProvoke />} />
        <Route path="identify" element={<AudibleIdentify />} />
        <Route path="strategize" element={<AudibleStrategize />} />
        <Route path="plan" element={<AudiblePlan />} />
        <Route path="activate" element={<AudibleActivate />} />
        <Route path="deploy" element={<AudibleDeploy />} />
        <Route path="report" element={<AudibleReport />} />
        <Route path="learn" element={<AudibleLearn />} />
      </Route>
      <Route path="fossil/*" element={<FossilRoutes />} />
      <Route path="nestle-health/*" element={<NestleHealthRoutes />} />
      <Route path="henkel/*" element={<HenkelRoutes />} />
      <Route path="ferrero/*" element={<FerreroRoutes />} />
      <Route path="asml/*" element={<ASMLRoutes />} />
      <Route path="tccc-widget" element={<CocaColaProcess />} />
      <Route path="tiger-brands/*" element={<TigerBrandsRoutes />} />
      <Route path="loreal/*" element={<LorealRoutes />} />
    </Routes>
  )
}

export default CustomProcessRoutes
