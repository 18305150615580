import logo from 'pages/customProcess/components/ibm/assets/2222.jpeg'
import image0_30_7727 from 'pages/customProcess/components/ibm/assets/image0_30_7727.jpeg'

export const IbmSvgDefs = () => {
  return (
    <defs>
      <filter
        id="filter0_f_30_7727"
        x="40"
        y="0"
        width="1360"
        height="1360"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="200" result="effect1_foregroundBlur_30_7727" />
      </filter>
      <filter
        id="filter1_d_30_7727"
        x="440"
        y="404"
        width="560"
        height="560"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="20" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_30_7727" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_30_7727" result="shape" />
      </filter>
      <filter
        id="filter2_d_30_7727"
        x="480"
        y="444"
        width="480"
        height="480"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="20" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_30_7727" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_30_7727" result="shape" />
      </filter>
      <filter
        id="filter3_d_30_7727"
        x="520"
        y="484"
        width="400"
        height="400"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="20" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_30_7727" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_30_7727" result="shape" />
      </filter>
      <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
        <image
          width="2218"
          height="3545"
          transform="matrix(0.0010449300752952695, 0, 0, 0.00043975401786156, -0.6635320782661438, -0.27968302369117737)"
          xlinkHref={logo}
        />
      </pattern>
      <filter
        id="filter4_d_30_7727"
        x="237"
        y="428"
        width="172.741"
        height="96"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="10" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_30_7727" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_30_7727" result="shape" />
      </filter>
      <filter
        id="filter5_d_30_7727"
        x="275"
        y="844"
        width="185.741"
        height="96"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="10" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_30_7727" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_30_7727" result="shape" />
      </filter>
      <filter
        id="filter6_d_30_7727"
        x="996"
        y="429"
        width="209.741"
        height="96"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="10" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_30_7727" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_30_7727" result="shape" />
      </filter>
      <filter
        id="filter8_d_30_7727"
        x="650"
        y="328"
        width="140"
        height="75"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="10" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_30_7727" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_30_7727" result="shape" />
      </filter>
      <filter
        id="filter9_d_30_7727"
        x="995"
        y="844"
        width="208.741"
        height="96"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="10" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_30_7727" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_30_7727" result="shape" />
      </filter>
      <filter
        id="filter10_d_30_7727"
        x="626.396"
        y="1012.19"
        width="185.741"
        height="96"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="10" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_30_7727" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_30_7727" result="shape" />
      </filter>
      <linearGradient id="paint0_linear_30_7727" x1="720" y1="360" x2="720" y2="1000" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="0.0001" stopColor="#0F62FE" />
        <stop offset="1" stopColor="#A6C8FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_30_7727"
        x1="441.859"
        y1="522.013"
        x2="720.142"
        y2="680.142"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#198038" />
        <stop offset="1" stopColor="#6FDC8C" />
      </linearGradient>
      <linearGradient id="paint2_linear_30_7727" x1="720" y1="360" x2="720" y2="680" gradientUnits="userSpaceOnUse">
        <stop stopColor="#DA1E28" />
        <stop offset="0.6561" stopColor="#FFB3B8" />
      </linearGradient>
      <linearGradient id="paint3_linear_30_7727" x1="997.5" y1="521.5" x2="720" y2="681" gradientUnits="userSpaceOnUse">
        <stop stopColor="#D02670" />
        <stop offset="1" stopColor="#FFAFD2" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_30_7727"
        x1="993.523"
        y1="845.154"
        x2="719.219"
        y2="680.219"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D02670" />
        <stop offset="0.0001" stopColor="#8A3FFC" />
        <stop offset="1" stopColor="#D4BBFF" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_30_7727"
        x1="718.052"
        y1="999.54"
        x2="719.444"
        y2="679.445"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0072C3" />
        <stop offset="1" stopColor="#82CFFF" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_30_7727"
        x1="438.627"
        y1="832.511"
        x2="719.883"
        y2="679.883"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#007D79" />
        <stop offset="1" stopColor="#3DDBD9" />
      </linearGradient>
      <radialGradient
        id="paint7_radial_30_7727"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(997 520) rotate(90) scale(20)"
      >
        <stop stopColor="#FFAFD2" />
        <stop offset="1" stopColor="#D02670" />
      </radialGradient>
      <radialGradient
        id="paint8_radial_30_7727"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(997 840) rotate(90) scale(20)"
      >
        <stop stopColor="#D4BBFF" />
        <stop offset="1" stopColor="#8A3FFC" />
      </radialGradient>
      <radialGradient
        id="paint9_radial_30_7727"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(720 1000) rotate(90) scale(20)"
      >
        <stop stopColor="#82CFFF" />
        <stop offset="1" stopColor="#0072C3" />
      </radialGradient>
      <radialGradient
        id="paint10_radial_30_7727"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(443 840) rotate(90) scale(20)"
      >
        <stop stopColor="#3DDBD9" />
        <stop offset="1" stopColor="#007D79" />
      </radialGradient>
      <radialGradient
        id="paint11_radial_30_7727"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(443 520) rotate(90) scale(20)"
      >
        <stop stopColor="#6FDC8C" />
        <stop offset="1" stopColor="#198038" />
      </radialGradient>
      <radialGradient
        id="paint12_radial_30_7727"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(503.515 551.921) rotate(30.6216) scale(251.73 120.31)"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint13_radial_30_7727"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(548.615 576.151) rotate(31.2272) scale(200.589 90.5978)"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint14_radial_30_7727"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(489.76 807.393) rotate(-29.1064) scale(262.238 125.332)"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint15_radial_30_7727"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(516.502 789.718) rotate(-28.5008) scale(230.289 104.012)"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint16_radial_30_7727"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(546.17 773.609) rotate(-28.5008) scale(196.53 88.7641)"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint17_radial_30_7727"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(714.251 932.265) rotate(-88.7357) scale(251.73 120.31)"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint18_radial_30_7727"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(713.259 881.078) rotate(-88.1301) scale(200.589 90.5978)"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint19_radial_30_7727"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(940.436 559.443) rotate(151.126) scale(251.73 120.31)"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint20_radial_30_7727"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(896.667 586) rotate(151.731) scale(200.589 90.5978)"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint21_linear_30_7727"
        x1="721.248"
        y1="275.098"
        x2="721.248"
        y2="401"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0%" stopColor="rgba(255,255,255, 0)" />
        <stop offset="20%" stopColor="#afcafd" />
        <stop offset="100%" stopColor="white" />
      </linearGradient>
      <filter
        id="filter7_d_30_7727"
        x="595"
        y="257"
        width="250"
        height="168"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="15" />
        <feGaussianBlur stdDeviation="8" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.085 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_30_7727" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_30_7727" result="shape" />
      </filter>
      <radialGradient
        id="paint22_radial_30_7727"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(931.621 813.738) rotate(-147.967) scale(251.73 120.31)"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint23_radial_30_7727"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(887.132 788.404) rotate(-147.361) scale(200.589 90.5978)"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <clipPath id="clip0_30_7727">
        <rect width="10.7413" height="19.765" fill="white" transform="translate(363 462.118)" />
      </clipPath>
      <clipPath id="clip1_30_7727">
        <rect width="10.7413" height="19.765" fill="white" transform="translate(414 878.118)" />
      </clipPath>
      <clipPath id="clip2_30_7727">
        <rect width="10.7413" height="19.765" fill="white" transform="translate(1159 463.118)" />
      </clipPath>
      <clipPath id="clip3_30_7727">
        <rect width="10.7413" height="19.765" fill="white" transform="translate(1157 878.118)" />
      </clipPath>
      <clipPath id="clip4_30_7727">
        <rect width="10.7413" height="19.765" fill="white" transform="translate(765.396 1046.3)" />
      </clipPath>
      <image id="image0_30_7727" width="2218" height="3545" xlinkHref={image0_30_7727} />
    </defs>
  )
}
