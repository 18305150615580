import ArchitectIcon from 'pages/customProcess/components/allianz/assets/Architect.svg'
import AudienceIcon from 'pages/customProcess/components/allianz/assets/Audience.svg'
import BavIcon from 'pages/customProcess/components/allianz/assets/BAV.svg'
import CopilotIcon from 'pages/customProcess/components/allianz/assets/Copilot.svg'
import MapIcon from 'pages/customProcess/components/allianz/assets/MAP.svg'
import MScreenIcon from 'pages/customProcess/components/allianz/assets/mSceen.svg'
import { Phases, PhasePoints, IPointPopupConfig } from 'pages/customProcess/components/allianz/types'

export const pointPopupsConfig: Record<PhasePoints, IPointPopupConfig> = {
  [PhasePoints.Adapt]: {
    description: 'Campaign measurement and optimisation',
    templates: 'Best Practice Optimisation Process guides',
    tools: [{ title: 'CoPilot®', logo: CopilotIcon }, { title: 'DPR®' }, { title: 'DPA®' }],
    placement: 'left-top',
    parrent: Phases.Discover,
  },
  [PhasePoints.Ambition]: {
    description: 'Defining the behaviour we want to change',
    templates: 'Campaign Brief template, Measurement Framework',
    tools: [{ title: 'BAV®', logo: BavIcon }],
    placement: 'left-top-short',
    parrent: Phases.Discover,
  },
  [PhasePoints.Audience]: {
    description: 'Develop our understanding of audience',
    tools: [{ title: 'Audience Origin/ADB®', logo: AudienceIcon }, { title: 'CIM®' }],
    placement: 'left-bottom',
    parrent: Phases.Design,
  },
  [PhasePoints.Approach]: {
    description: 'Develop our Strategic media approach',
    tools: [{ title: 'Architect®', logo: ArchitectIcon }],
    button: {
      title: 'Go to stage details',
      url: '/custom/approach',
    },
    placement: 'right-bottom',
    parrent: Phases.Design,
  },
  [PhasePoints.Assets]: {
    description: 'Define our detailed media plans',
    templates: 'Media Plan Template',
    tools: [
      { title: 'MAP®', logo: MapIcon },
      { title: 'mScreen®', logo: MScreenIcon },
      { title: 'Carbon Calculator®' },
      { title: 'Data Ethics Compass®' },
    ],
    button: {
      title: 'Go to stage details',
      url: '/custom/assets-ideas',
    },
    placement: 'right-bottom-short',
    parrent: Phases.Deliver,
  },
  [PhasePoints.Activate]: {
    description: 'Campaign setup and launch',
    templates: 'QA Process for each channel and AdOps',
    tools: [{ title: 'OCTRA®' }],
    placement: 'right-top',
    parrent: Phases.Deliver,
  },
}
