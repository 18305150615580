import { WppTypography } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import clsx from 'clsx'
import Lottie, { LottieRefCurrentProps } from 'lottie-react'
import { useState, useCallback, useRef, useEffect } from 'react'

import { Flex } from 'components/common/flex/Flex'
import briefArrowHeadUrl from 'pages/customProcess/components/unilever/assets/brief-arrow-head.svg'
import ideationCircleShadowUrl from 'pages/customProcess/components/unilever/assets/ideation-circle-shadow.png'
import lineAnimation from 'pages/customProcess/components/unilever/assets/line-animation.json'
import unileverLogoUrl from 'pages/customProcess/components/unilever/assets/logo-unilever.svg'
import titleSvgUrl from 'pages/customProcess/components/unilever/assets/title.svg'
import { BriefPhaseDetails } from 'pages/customProcess/components/unilever/components/briefPhaseDetails/BriefPhaseDetails'
import { IdeationPhaseDetails } from 'pages/customProcess/components/unilever/components/ideationPhaseDetails/IdeationPhaseDetails'
import { ImpactPhaseDetails } from 'pages/customProcess/components/unilever/components/impactPhaseDetails/ImpactPhaseDetails'
import { InspirationPhaseDetails } from 'pages/customProcess/components/unilever/components/inspirationPhaseDetails/InspirationPhaseDetails'
import { LearningPhaseDetails } from 'pages/customProcess/components/unilever/components/learningPhaseDetails/LearningPhaseDetails'
import { ProductionPhaseDetails } from 'pages/customProcess/components/unilever/components/productionPhaseDetails/ProductionPhaseDetails'
import styles from 'pages/customProcess/components/unilever/UnileverProcess.module.scss'

enum Phases {
  Brief = 'Brief',
  Inspiration = 'Inspiration',
  Ideation = 'Ideation',
  Impact = 'Impact',
  Production = 'Production',
  Learning = 'Learning',
}

export const UnileverProcess = () => {
  const [activePhase, setActivePhase] = useState<MayBeNull<Phases>>(null)
  const [allowTitleAnimations, setAllowTitleAnimations] = useState(true)

  const lineAnimationRef = useRef<LottieRefCurrentProps>(null)

  useEffect(() => {
    const lineAnimationDelay = setTimeout(() => {
      lineAnimationRef?.current?.play()
    }, 1500)

    return () => {
      clearTimeout(lineAnimationDelay)
    }
  }, [lineAnimationRef])

  useEffect(() => {
    const titleAnimationsDelay = setTimeout(() => {
      setAllowTitleAnimations(false)
    }, 3100)

    return () => {
      clearTimeout(titleAnimationsDelay)
    }
  }, [])

  const handlePhaseEnter = useCallback(
    (phase: Phases) => () => {
      if (!allowTitleAnimations) {
        setActivePhase(phase)
      }
    },
    [allowTitleAnimations],
  )

  const handlePhaseLeave = useCallback(() => {
    setActivePhase(null)
  }, [])

  return (
    <div className={styles.root}>
      <Flex direction="column" justify="center" align="center" className={styles.schemeContainer}>
        <img alt="" src={titleSvgUrl} className={styles.title} />
        <Lottie
          lottieRef={lineAnimationRef}
          animationData={lineAnimation}
          loop={false}
          autoplay={false}
          className={clsx(
            styles.lineGif,
            (activePhase === Phases.Learning || activePhase === Phases.Brief) && styles.bringToFront,
          )}
        />
        <img src={unileverLogoUrl} alt="unilever logo" className={styles.unileverLogo} />
        <Flex align="center" className={styles.circlesOffset}>
          <div>
            <div
              className={clsx(
                styles.briefCircleClosed,
                styles.backdropPhaseCircle,
                !activePhase && styles.backdropPhaseCircleHidden,
              )}
            />
            <Flex
              justify="center"
              align="center"
              className={clsx(
                styles.briefCircleClosed,
                activePhase === Phases.Brief && styles.briefCircle,
                activePhase === Phases.Learning && styles.bringToFront,
              )}
              onMouseEnter={handlePhaseEnter(Phases.Brief)}
              onMouseLeave={handlePhaseLeave}
            >
              <img
                src={briefArrowHeadUrl}
                alt="brief arrow head"
                className={clsx(styles.briefArrowHead, activePhase === Phases.Brief && styles.briefArrowHeadActive)}
              />
              {activePhase === Phases.Brief ? (
                <div className={styles.briefDetails}>
                  <BriefPhaseDetails />
                </div>
              ) : (
                <WppTypography
                  className={clsx(
                    styles.smallCircleClosedText,
                    allowTitleAnimations ? styles.briefTitle : styles.noOpacity,
                  )}
                  style={{ width: '85px' }}
                >
                  Brief creation
                </WppTypography>
              )}
            </Flex>
          </div>
          <div>
            <div
              className={clsx(
                styles.inspirationCircleClosed,
                styles.backdropPhaseCircle,
                !activePhase && styles.backdropPhaseCircleHidden,
              )}
            />
            <Flex
              justify="center"
              align="center"
              className={clsx(
                styles.inspirationCircleClosed,
                activePhase === Phases.Inspiration && styles.inspirationCircle,
              )}
              onMouseEnter={handlePhaseEnter(Phases.Inspiration)}
              onMouseLeave={handlePhaseLeave}
            >
              {activePhase === Phases.Inspiration ? (
                <div className={styles.inspirationDetails}>
                  <InspirationPhaseDetails triggerRotateSvg={activePhase === Phases.Inspiration} />
                </div>
              ) : (
                <WppTypography
                  className={clsx(
                    styles.largeCircleClosedText,
                    allowTitleAnimations ? styles.inspirationTitle : styles.noOpacity,
                  )}
                >
                  Inspiration
                </WppTypography>
              )}
            </Flex>
          </div>
          <div>
            <div
              className={clsx(
                styles.ideationCircleClosed,
                styles.backdropPhaseCircle,
                !activePhase && styles.backdropPhaseCircleHidden,
              )}
            />
            <Flex
              justify="center"
              align="center"
              className={clsx(styles.ideationCircleClosed, activePhase === Phases.Ideation && styles.ideationCircle)}
              onMouseEnter={handlePhaseEnter(Phases.Ideation)}
              onMouseLeave={handlePhaseLeave}
            >
              <img
                alt=""
                src={ideationCircleShadowUrl}
                className={clsx(
                  styles.ideationCircleShadow,
                  activePhase === Phases.Ideation && styles.inspirationDetails,
                )}
              />
              {activePhase === Phases.Ideation ? (
                <div className={styles.inspirationDetails}>
                  <IdeationPhaseDetails triggerRotateSvg={activePhase === Phases.Ideation} />
                </div>
              ) : (
                <WppTypography
                  className={clsx(
                    styles.largeCircleClosedText,
                    allowTitleAnimations ? styles.ideationTitle : styles.noOpacity,
                  )}
                >
                  Ideation
                </WppTypography>
              )}
            </Flex>
          </div>
          <div>
            <div
              className={clsx(
                styles.impactCircleClosed,
                styles.backdropPhaseCircle,
                !activePhase && styles.backdropPhaseCircleHidden,
              )}
            />
            <Flex
              justify="center"
              align="center"
              className={clsx(styles.impactCircleClosed, activePhase === Phases.Impact && styles.impactCircle)}
              onMouseEnter={handlePhaseEnter(Phases.Impact)}
              onMouseLeave={handlePhaseLeave}
            >
              {activePhase === Phases.Impact ? (
                <div className={styles.inspirationDetails}>
                  <ImpactPhaseDetails triggerRotateSvg={activePhase === Phases.Impact} />
                </div>
              ) : (
                <WppTypography
                  className={clsx(
                    styles.largeCircleClosedText,
                    allowTitleAnimations ? styles.impactTitle : styles.noOpacity,
                  )}
                >
                  Impact
                </WppTypography>
              )}
            </Flex>
          </div>
          <div>
            <div
              className={clsx(
                styles.productionCircleClosed,
                styles.backdropPhaseCircle,
                !activePhase && styles.backdropPhaseCircleHidden,
              )}
            />
            <Flex
              justify="center"
              align="center"
              className={clsx(
                styles.productionCircleClosed,
                activePhase === Phases.Production && styles.productionCircle,
              )}
              onMouseEnter={handlePhaseEnter(Phases.Production)}
              onMouseLeave={handlePhaseLeave}
            >
              {activePhase === Phases.Production ? (
                <div className={styles.productionDetails}>
                  <ProductionPhaseDetails />
                </div>
              ) : (
                <WppTypography
                  className={clsx(
                    styles.smallCircleClosedText,
                    allowTitleAnimations ? styles.productionTitle : styles.noOpacity,
                  )}
                >
                  Production
                </WppTypography>
              )}
            </Flex>
          </div>
          <div>
            <div
              className={clsx(
                styles.learningCircleClosed,
                styles.backdropPhaseCircle,
                !activePhase && styles.backdropPhaseCircleHidden,
              )}
            />
            <Flex
              justify="center"
              align="center"
              className={clsx(
                styles.learningCircleClosed,
                activePhase === Phases.Learning && styles.learningCircle,
                activePhase === Phases.Brief && styles.bringToFront,
              )}
              onMouseEnter={handlePhaseEnter(Phases.Learning)}
              onMouseLeave={handlePhaseLeave}
            >
              <div
                className={clsx(
                  styles.learningArrowFoot,
                  activePhase === Phases.Learning && styles.learningArrowFootActive,
                )}
              />
              {activePhase === Phases.Learning ? (
                <div className={styles.productionDetails}>
                  <LearningPhaseDetails />
                </div>
              ) : (
                <WppTypography
                  className={clsx(
                    styles.smallCircleClosedText,
                    allowTitleAnimations ? styles.learningTitle : styles.noOpacity,
                  )}
                >
                  Learning
                </WppTypography>
              )}
            </Flex>
          </div>
        </Flex>
      </Flex>
      <div className={clsx(styles.blurOverlay, activePhase !== null && styles.blurOverlayActive)} />
    </div>
  )
}
