import schemeImg from 'pages/customProcess/components/audible/assets/one pagers_activate.png'
import { ProcessCard } from 'pages/customProcess/components/audible/processPhases/processCard/ProcessCard'

const Activate = () => {
  return (
    <ProcessCard
      type="design"
      title="Activate"
      description="Build the appropriate assets and build an infrastructure to bring our plan to life"
      previousLink="/custom/audible/plan"
      nextLink="/custom/audible/deploy"
    >
      <img src={schemeImg} alt="" />
    </ProcessCard>
  )
}

export default Activate
