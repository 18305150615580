// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes NUk8B{100%{transform:rotate(1turn)}}.wHcqD{transform-origin:center calc(50% + 1px);animation:NUk8B 1.5s ease-in-out forwards}", "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/unilever/components/impactPhaseDetails/components/impactSvg/ImpactSvg.module.scss"],"names":[],"mappings":"AAAA,iBACE,KACE,uBAAA,CAAA,CAIJ,OACE,uCAAA,CACA,yCAAA","sourcesContent":["@keyframes spin {\n  100% {\n    transform: rotate(1turn);\n  }\n}\n\n.rotate {\n  transform-origin: center calc(50% + 1px);\n  animation: spin 1.5s ease-in-out forwards;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rotate": "wHcqD",
	"spin": "NUk8B"
};
export default ___CSS_LOADER_EXPORT___;
