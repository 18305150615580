import clsx from 'clsx'
import { useState } from 'react'

import { Flex } from 'components/common/flex/Flex'
import lampLightImgUrl from 'pages/customProcess/components/ikea/assets/lamp-light.svg'
import lampManImgUrl from 'pages/customProcess/components/ikea/assets/lamp-man.svg'
import { PhaseCard } from 'pages/customProcess/components/ikea/components/phaseCard/PhaseCard'
import styles from 'pages/customProcess/components/ikea/IkeaProcess.module.scss'
import { IkeaProcessData } from 'pages/customProcess/components/ikea/IkeaProcessData'

export const IkeaProcess = () => {
  const [activePhaseIndex, setActivePhaseIndex] = useState(0)

  const handlePhaseChange = (index: number) => {
    if (index === activePhaseIndex) {
      return
    }
    setActivePhaseIndex(index)
  }

  return (
    <Flex direction="column" justify="center" className={styles.root}>
      <div className={styles.pageContent}>
        <h1 className={styles.title}>{'Accelerated Growth\nPlanning Process'}</h1>
        <p className={styles.description}>
          {'Building desire through multi-platform, co-ordinated,\nintegrated and distinctive comms experiences'}
        </p>
        <img src={lampManImgUrl} alt="Lamp Man illustration" className={styles.lampManIllustration} />
        <img src={lampLightImgUrl} alt="Lamp Light illustration" className={styles.lampLightIllustration} />
        <Flex gap={4}>
          {IkeaProcessData.map((phase, index) => (
            <PhaseCard
              isActive={activePhaseIndex === index}
              index={index}
              title={phase.title}
              link={phase.link}
              description={phase.description}
              onPhaseClick={handlePhaseChange}
              key={index}
            />
          ))}
        </Flex>
        <Flex gap={12}>
          <Flex
            className={clsx(
              styles.cycleMarkerTransition,
              activePhaseIndex <= 1 ? styles.marketingCycleMarkerActive : styles.marketingCycleMarker,
            )}
          >
            <div className={styles.cycleBorderLeft} />
            <div className={styles.relativePos}>
              <div className={styles.cycleTitle}>{'Marketing\nCycle'}</div>
              <div className={clsx(styles.cycleSubtitle, styles.marketingCycleSubtitle)}>
                {'Identifying the opportunity\nfor growing IKEA’s business'}
              </div>
            </div>
            <div className={styles.cycleBorderRight} />
          </Flex>
          <Flex
            className={clsx(
              styles.cycleMarkerTransition,
              activePhaseIndex > 1 && activePhaseIndex <= 4
                ? styles.campaignCycleMarkerActive
                : styles.campaignCycleMarker,
            )}
          >
            <div className={styles.cycleBorderLeft} />
            <div className={styles.relativePos}>
              <div className={styles.cycleTitle}>{'Campaign\nCycle'}</div>
              <div className={clsx(styles.cycleSubtitle, styles.campaignCycleSubtitle)}>
                {'Designing the communications system\nto deliver to the identified opportunity'}
              </div>
            </div>
            <div className={styles.cycleBorderRight} />
          </Flex>
          <Flex
            className={clsx(
              styles.cycleMarkerTransition,
              activePhaseIndex === 5 ? styles.optimisationCycleMarkerActive : styles.optimisationCycleMarker,
            )}
          >
            <div className={styles.cycleBorderLeft} />
            <div className={styles.relativePos}>
              <div className={styles.cycleTitle}>{'Optimisation\nCycle'}</div>
              <div className={clsx(styles.cycleSubtitle, styles.optimisationCycleSubtitle)}>
                {'The test, learn and\nin-flight optimisation plan'}
              </div>
            </div>
            <div className={styles.cycleBorderRight} />
          </Flex>
        </Flex>
      </div>
    </Flex>
  )
}
