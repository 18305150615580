import { WppTypography, WppIconUser } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/customProcess/components/unilever/components/briefPhaseDetails/BriefPhaseDetails.module.scss'

export const BriefPhaseDetails = () => (
  <Flex direction="column" align="center" className={styles.container}>
    <WppTypography className={styles.title}>Brief creation</WppTypography>
    <WppTypography type="s-body" className={styles.description}>
      Co-create Client Brief{'\n'} Inspiration workshops - where required
    </WppTypography>
    <Flex gap={4}>
      <WppIconUser color="var(--wpp-grey-color-000)" />
      <WppTypography type="s-body" className={styles.whiteText}>
        Strategy
      </WppTypography>
    </Flex>
  </Flex>
)
