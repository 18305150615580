// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SpVGW{position:relative;height:calc(100vh - var(--wpp-os-header-height));overflow:hidden}.ZHVSi{width:100%;max-height:100%;transform:scale(1.3)}.Z4hT8{cursor:pointer}", "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/ibm/IbmProcess.module.scss"],"names":[],"mappings":"AAAA,OACE,iBAAA,CACA,gDAAA,CACA,eAAA,CAGF,OACE,UAAA,CACA,eAAA,CACA,oBAAA,CAGF,OACE,cAAA","sourcesContent":[".root {\n  position: relative;\n  height: calc(100vh - var(--wpp-os-header-height));\n  overflow: hidden;\n}\n\n.svg {\n  width: 100%;\n  max-height: 100%;\n  transform: scale(1.3);\n}\n\n.svgPoint {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "SpVGW",
	"svg": "ZHVSi",
	"svgPoint": "Z4hT8"
};
export default ___CSS_LOADER_EXPORT___;
