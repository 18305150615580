import { WppTypography, WppIconUser } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/customProcess/components/unilever/components/productionPhaseDetails/ProductionPhaseDetails.module.scss'

export const ProductionPhaseDetails = () => (
  <Flex direction="column" align="center" className={styles.container}>
    <WppTypography className={styles.title}>Production</WppTypography>
    <WppTypography type="s-body" className={styles.description}>
      Production of Masters (TV, Print, Digital)
    </WppTypography>
    <Flex gap={8}>
      <Flex gap={4}>
        <WppIconUser color="var(--wpp-grey-color-000)" />
        <WppTypography type="s-body" className={styles.whiteText}>
          Creative
        </WppTypography>
      </Flex>
      <Flex gap={4}>
        <WppIconUser color="var(--wpp-grey-color-000)" />
        <WppTypography type="s-body" className={styles.whiteText}>
          Production
        </WppTypography>
      </Flex>
    </Flex>
  </Flex>
)
