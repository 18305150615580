import schemeImg from 'pages/customProcess/components/audible/assets/one pagers_report.png'
import { ProcessCard } from 'pages/customProcess/components/audible/processPhases/processCard/ProcessCard'

const Report = () => {
  return (
    <ProcessCard
      type="learn"
      title="Report"
      description="Evaluation of our media effort's impact across KPIs and diagnostic metrics"
      previousLink="/custom/audible/deploy"
      nextLink="/custom/audible/learn"
    >
      <img src={schemeImg} alt="" />
    </ProcessCard>
  )
}

export default Report
