// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cB62s{width:340px;padding-top:32px;text-align:center;-webkit-user-select:none;user-select:none}.w387S{margin-bottom:4px;color:var(--wpp-grey-color-000)}.w387S::part(typography){font-weight:700;font-size:18px;line-height:24px;letter-spacing:2px;text-transform:uppercase}.dhpO6{margin-bottom:32px;color:var(--wpp-grey-color-000);white-space:pre-wrap}.fZv6E{color:var(--wpp-grey-color-000)}", "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/unilever/components/productionPhaseDetails/ProductionPhaseDetails.module.scss"],"names":[],"mappings":"AAAA,OACE,WAAA,CACA,gBAAA,CACA,iBAAA,CACA,wBAAA,CAAA,gBAAA,CAGF,OACE,iBAAA,CACA,+BAAA,CAEA,yBACE,eAAA,CACA,cAAA,CACA,gBAAA,CACA,kBAAA,CACA,wBAAA,CAIJ,OACE,kBAAA,CACA,+BAAA,CACA,oBAAA,CAGF,OACE,+BAAA","sourcesContent":[".container {\n  width: 340px;\n  padding-top: 32px;\n  text-align: center;\n  user-select: none;\n}\n\n.title {\n  margin-bottom: 4px;\n  color: var(--wpp-grey-color-000);\n\n  &::part(typography) {\n    font-weight: 700;\n    font-size: 18px;\n    line-height: 24px;\n    letter-spacing: 2px;\n    text-transform: uppercase;\n  }\n}\n\n.description {\n  margin-bottom: 32px;\n  color: var(--wpp-grey-color-000);\n  white-space: pre-wrap;\n}\n\n.whiteText {\n  color: var(--wpp-grey-color-000);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "cB62s",
	"title": "w387S",
	"description": "dhpO6",
	"whiteText": "fZv6E"
};
export default ___CSS_LOADER_EXPORT___;
