import { FileItemType } from '@platform-ui-kit/components-library'
import { MayBeNull, AttachmentMetadataResponse, AttachmentMetadata } from '@wpp-open/core'

export enum Templates {
  Circular1 = 'FIRST_CIRCULAR',
  Circular2 = 'SECOND_CIRCULAR',
}

export interface Phase {
  id: string
  processId?: MayBeNull<string>
  parentId: MayBeNull<string>
  title: MayBeNull<string>
  innerPageEnabled: boolean
  groupTitle: MayBeNull<string>
  overlayDescriptions: string[]
  description: MayBeNull<string>
  colors: string[]
  url: MayBeNull<string>
  order?: MayBeNull<number>
  children: Omit<Phase, 'children'>[]
}

export interface ProcessButton {
  id: string
  processId?: MayBeNull<string>
  title?: MayBeNull<string>
  url?: MayBeNull<string>
  colors: string[]
  isVisible: boolean
}

export interface Process {
  id: string
  title?: MayBeNull<string>
  tenantId: string
  template: Templates
  colors: string[]
  button?: MayBeNull<ProcessButton>
  phases: Phase[]
  createdAt: string
  createdBy: string
  updatedAt?: MayBeNull<string>
  updatedBy?: MayBeNull<string>
  background: MayBeNull<AttachmentMetadataResponse>
  logo?: MayBeNull<AttachmentMetadataResponse>
}

export interface PayloadPhase
  extends Partial<Omit<Phase, 'id' | 'processId' | 'overlayDescriptions' | 'colors' | 'children'>> {
  overlayDescriptions?: MayBeNull<string[]>
  colors?: MayBeNull<string[]>
  children?: MayBeNull<Omit<PayloadPhase, 'children'>[]>
}

export interface PayloadButton extends Omit<ProcessButton, 'id' | 'processId' | 'colors' | 'isVisible'> {
  colors?: MayBeNull<string[]>
  isVisible?: MayBeNull<boolean>
}

export interface EditProcessPayload {
  title?: MayBeNull<string>
  tenantId?: MayBeNull<string>
  phases?: MayBeNull<PayloadPhase[]>
  button?: MayBeNull<PayloadButton>
  template?: MayBeNull<Templates>
  colors?: MayBeNull<string[]>
  background?: MayBeNull<AttachmentMetadata>
  logo?: MayBeNull<AttachmentMetadata>
}

export interface CreateProcessPayload extends EditProcessPayload {
  tenantId: string
  template: Templates
}

export interface FormPhase extends Omit<Phase, 'overlayDescriptions' | 'id'> {
  overlayDescriptions: { label: string; id: number }[]
  id?: string
}

export interface ProcessFormConfig
  extends Omit<Process, 'id' | 'tenantId' | 'createdAt' | 'createdBy' | 'background' | 'logo' | 'button' | 'phases'> {
  background: [FileItemType?]
  logo: [FileItemType?]
  button?: MayBeNull<PayloadButton>
  phases: FormPhase[]
}
