// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jK36x{width:420px;text-align:center;-webkit-user-select:none;user-select:none}.fcl73{margin-bottom:6px;color:var(--wpp-grey-color-000)}.fcl73:hover{text-decoration:underline}.goLTA{color:var(--wpp-grey-color-000)}.goLTA::part(typography){font-weight:700;font-size:18px;line-height:24px;letter-spacing:2px;text-transform:uppercase}.GLeA7{color:var(--wpp-grey-color-000)}.RKScV{margin-top:24px;margin-bottom:-12px}", "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/unilever/components/ideationPhaseDetails/IdeationPhaseDetails.module.scss"],"names":[],"mappings":"AAAA,OACE,WAAA,CACA,iBAAA,CACA,wBAAA,CAAA,gBAAA,CAGF,OACE,iBAAA,CACA,+BAAA,CAEA,aACE,yBAAA,CAIJ,OACE,+BAAA,CAEA,yBACE,eAAA,CACA,cAAA,CACA,gBAAA,CACA,kBAAA,CACA,wBAAA,CAIJ,OACE,+BAAA,CAGF,OACE,eAAA,CACA,mBAAA","sourcesContent":[".container {\n  width: 420px;\n  text-align: center;\n  user-select: none;\n}\n\n.link {\n  margin-bottom: 6px;\n  color: var(--wpp-grey-color-000);\n\n  &:hover {\n    text-decoration: underline;\n  }\n}\n\n.title {\n  color: var(--wpp-grey-color-000);\n\n  &::part(typography) {\n    font-weight: 700;\n    font-size: 18px;\n    line-height: 24px;\n    letter-spacing: 2px;\n    text-transform: uppercase;\n  }\n}\n\n.whiteText {\n  color: var(--wpp-grey-color-000);\n}\n\n.roles {\n  margin-top: 24px;\n  margin-bottom: -12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "jK36x",
	"link": "fcl73",
	"title": "goLTA",
	"whiteText": "GLeA7",
	"roles": "RKScV"
};
export default ___CSS_LOADER_EXPORT___;
