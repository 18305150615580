import schemeImg from 'pages/customProcess/components/audible/assets/one pagers_deploy.png'
import { ProcessCard } from 'pages/customProcess/components/audible/processPhases/processCard/ProcessCard'

const Deploy = () => {
  return (
    <ProcessCard
      type="learn"
      title="Deploy and optimise"
      description="Launch and optimize each channel to maximize chances of success"
      previousLink="/custom/audible/activate"
      nextLink="/custom/audible/report"
    >
      <img src={schemeImg} alt="" />
    </ProcessCard>
  )
}

export default Deploy
