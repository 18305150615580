const ORIGIN = window.location.origin

export const routesManager = Object.freeze({
  process: {
    url: '/',
  },
  phase: {
    url: '/phase/:phaseId',
    getUrl: (id: string) => `/phase/${id}`,
  },
  customProcess: {
    url: '/custom',
  },
  notFound: {
    url: '/404',
  },
  systemUrls: {
    core: {
      root: {
        url: ORIGIN,
      },
    },
    orchestration: {
      root: {
        url: `${ORIGIN}/orchestration`,
      },
      project: {
        getUrl: (id: string) => `${ORIGIN}/orchestration/project/${id}`,
      },
    },
  },
})
