export enum Tenants {
  Audible = 'Audible',
  Boots = 'Boots',
  Pfizer = 'Pfizer',
  EasyJet = 'EasyJet',
  Nestle = 'Nestle',
  NestleChina = 'Nestle China',
  NestleAsia = 'Nestle-Asia',
  NestleAOA = 'Nestle AOA',
  NestleHealthScience = 'Nestle Health Science',
  NestleUK = 'Nestle UK',
  NestleMEAP = 'Nestle MEAP',
  Beiersdorf = 'Beiersdorf',
  AXA = 'AXA',
  Orange = 'Orange',
  Aldi = 'Aldi',
  Perrigo = 'Perrigo',
  Panera = 'Panera',
  Allianz = 'Allianz',
  Spotify = 'Spotify',
  Tapestry = 'Tapestry',
  Unilever = 'Unilever',
  Google = 'Google',
  Mattressfirm = 'Mattressfirm',
  IBM = 'IBM',
  Ikea = 'Ikea',
  Vodafone = 'Vodafone',
  TCCC = 'TCCC',
  No7Beauty = 'no7beauty',
  Ferrero = 'Ferrero',
  Fossil = 'Fossil',
  WarnerBrothers = 'Warner Brothers',
  LVMH = 'LVMH',
  Samsung = 'Samsung',
  Henkel = 'Henkel',
  ProcterAndGamble = 'Procter & Gamble',
  ASML = 'ASML',
  Netflix = 'Netflix',
  Loccitane = 'Loccitane',
  FordMotorChina = 'Ford Motor China',
  TigerBrands = 'Tiger Brands',
  Loreal = 'Loreal',
}
