import schemeImg from 'pages/customProcess/components/audible/assets/one pagers_plan.png'
import { ProcessCard } from 'pages/customProcess/components/audible/processPhases/processCard/ProcessCard'

const Plan = () => {
  return (
    <ProcessCard
      type="design"
      title="Plan"
      description="Determine the most appropriate channel mix and tactics to deliver on the strategy"
      previousLink="/custom/audible/strategize"
      nextLink="/custom/audible/activate"
    >
      <img src={schemeImg} alt="" />
    </ProcessCard>
  )
}

export default Plan
