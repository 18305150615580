import schemeImg from 'pages/customProcess/components/procterAndGamble/assets/activation.png'
import { ProcessPandGCard } from 'pages/customProcess/components/procterAndGamble/processPhases/processPandGCard/ProcessPandGCard'

const Activation = () => {
  return (
    <ProcessPandGCard
      title="Activation"
      description="Audience understanding flows directly into annual campaign planning & activations. to keep track of NOS impact of any budget changes"
      previousLink="/custom/p&g/allocation"
      nextLink="/custom/p&g/attribution"
    >
      <img src={schemeImg} alt="" />
    </ProcessPandGCard>
  )
}

export default Activation
