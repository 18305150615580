import { WppTypography, WppIconChevron, WppIconUser } from '@platform-ui-kit/components-library-react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { ImpactSvg } from 'pages/customProcess/components/unilever/components/impactPhaseDetails/components/impactSvg/ImpactSvg'
import styles from 'pages/customProcess/components/unilever/components/impactPhaseDetails/ImpactPhaseDetails.module.scss'

interface Props {
  triggerRotateSvg?: boolean
}

export const ImpactPhaseDetails = ({ triggerRotateSvg }: Props) => (
  <Flex direction="column" align="center" className={styles.container}>
    <Link to="/custom/impact" className={styles.link}>
      <Flex align="center" gap={4}>
        <WppTypography className={styles.title}>Impact</WppTypography>
        <WppIconChevron color="var(--wpp-grey-color-000)" />
      </Flex>
    </Link>
    <WppTypography type="s-body" className={styles.whiteText}>
      Idea to Execution
    </WppTypography>
    <div className={styles.roles}>
      <Flex justify="center" gap={8} style={{ marginBottom: '5px' }}>
        <Flex gap={4}>
          <WppIconUser color="var(--wpp-grey-color-000)" />
          <WppTypography type="s-body" className={styles.whiteText}>
            Creative
          </WppTypography>
        </Flex>
        <Flex gap={4}>
          <WppIconUser color="var(--wpp-grey-color-000)" />
          <WppTypography type="s-body" className={styles.whiteText}>
            Strategy
          </WppTypography>
        </Flex>
        <Flex gap={4}>
          <WppIconUser color="var(--wpp-grey-color-000)" />
          <WppTypography type="s-body" className={styles.whiteText}>
            Media
          </WppTypography>
        </Flex>
        <Flex gap={4}>
          <WppIconUser color="var(--wpp-grey-color-000)" />
          <WppTypography type="s-body" className={styles.whiteText}>
            Social Media
          </WppTypography>
        </Flex>
      </Flex>
      <Flex justify="center" gap={8} style={{ marginBottom: '5px' }}>
        <Flex gap={4}>
          <WppIconUser color="var(--wpp-grey-color-000)" />
          <WppTypography type="s-body" className={styles.whiteText}>
            Influencer
          </WppTypography>
        </Flex>
        <Flex gap={4}>
          <WppIconUser color="var(--wpp-grey-color-000)" />
          <WppTypography type="s-body" className={styles.whiteText}>
            Production
          </WppTypography>
        </Flex>
        <Flex gap={4}>
          <WppIconUser color="var(--wpp-grey-color-000)" />
          <WppTypography type="s-body" className={styles.whiteText}>
            PR
          </WppTypography>
        </Flex>
        <Flex gap={4}>
          <WppIconUser color="var(--wpp-grey-color-000)" />
          <WppTypography type="s-body" className={styles.whiteText}>
            Creative Technologist
          </WppTypography>
        </Flex>
      </Flex>
      <Flex justify="center" gap={8}>
        <Flex gap={4}>
          <WppIconUser color="var(--wpp-grey-color-000)" />
          <WppTypography type="s-body" className={styles.whiteText}>
            Tech & Media Partners
          </WppTypography>
        </Flex>
        <Flex gap={4}>
          <WppIconUser color="var(--wpp-grey-color-000)" />
          <WppTypography type="s-body" className={styles.whiteText}>
            Commerce-Social/Retail
          </WppTypography>
        </Flex>
      </Flex>
    </div>
    <ImpactSvg triggerRotateSvg={triggerRotateSvg} />
  </Flex>
)
