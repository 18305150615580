import { WppActionButton, WppIconChevron, WppTypography, WppButton } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { ReactNode, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/phaseBuilder/templates/simpleCard/processCard/ProcessCard.module.scss'
import { getPreviewImageUrl } from 'pages/phaseBuilder/utils'
import { PhaseFormConfig } from 'types/phase/phase'
import { routesManager } from 'utils/routesManager'

interface Props {
  children: ReactNode
  config: PhaseFormConfig
}

export const ProcessCard = ({ children, config }: Props) => {
  const { t } = useTranslation()
  const isHeaderTransparent = useMemo(
    () => !config.header?.colors?.[1] && !config.header?.background[0],
    [config.header?.background, config.header?.colors],
  )

  return (
    <div className={styles.root}>
      <div
        className={clsx([
          styles.header,
          {
            [styles.headerTransparent]: isHeaderTransparent,
          },
        ])}
        style={{ backgroundImage: `url(${getPreviewImageUrl(config?.header?.background)})` }}
      >
        <div className={styles.bgColor} style={{ background: config?.header?.colors?.[1] }} />
        <Flex justify="between" align="center" gap={24} className={styles.headerContent}>
          <div className={styles.headerContentLeft}>
            <Link to={routesManager.process.url}>
              <WppActionButton variant="inverted" className={styles.backButton}>
                <WppIconChevron direction="left" color={config?.header?.colors?.[0]} />
                <span style={{ color: config?.header?.colors?.[0] }}>{t('phase.page.back_to_process')}</span>
              </WppActionButton>
            </Link>
            <WppTypography
              type="4xl-display"
              className={styles.headerTitle}
              style={{ color: config?.header?.colors?.[0] }}
            >
              {config?.header?.title}
            </WppTypography>
            <WppTypography
              type="s-body"
              style={{ color: config?.header?.colors?.[0] }}
              className={styles.headerDescription}
            >
              {config?.header?.description}
            </WppTypography>
          </div>
          <img src={getPreviewImageUrl(config?.header?.logo)} alt="" className={styles.logo} />
        </Flex>
      </div>

      <div
        className={clsx(styles.mainContent, {
          [styles.withTransparentHeader]: isHeaderTransparent,
        })}
      >
        <div
          className={clsx([styles.processCard])}
          style={{
            backgroundImage: `url(${getPreviewImageUrl(config?.card?.background)})`,
          }}
        >
          <div className={styles.cardBgColor} style={{ background: config?.card?.colors?.[0] }} />

          <Flex direction="column" justify="between" gap={24} className={styles.cardContent}>
            <div className={styles.flexGrow}>
              <Flex align="center" className={styles.descriptionCardsWrapper}>
                <Flex justify="between" gap={24} className={styles.descriptionCardsWrapperInner}>
                  {children}
                </Flex>
              </Flex>
            </div>
          </Flex>

          <Flex align="center" justify="between" className={styles.processControls}>
            <Flex gap={16}>
              <Link to={config?.controls?.previousUrl ?? ''}>
                <WppActionButton variant="secondary" disabled={!config?.controls?.previousUrl}>
                  <WppIconChevron direction="left" color={config?.controls?.colors?.[0]} />
                  <span style={{ color: config?.controls?.colors?.[0] }}>{t('phase.page.previous_phase')}</span>
                </WppActionButton>
              </Link>
              <Link to={config?.controls?.nextUrl ?? ''}>
                <WppActionButton variant="secondary" disabled={!config?.controls?.nextUrl}>
                  <span style={{ color: config?.controls?.colors?.[0] }}>{t('phase.page.next_phase')} </span>
                  <WppIconChevron color={config?.controls?.colors?.[0]} />
                </WppActionButton>
              </Link>
            </Flex>
            {config?.controls?.button?.isVisible && (
              <Link to={config?.controls?.button?.url || ''}>
                <WppButton
                  className={styles.projectBtn}
                  style={{ background: config?.controls?.colors?.[1], color: config?.controls?.colors?.[2] }}
                >
                  {config?.controls?.button?.title}
                </WppButton>
              </Link>
            )}
          </Flex>
        </div>
      </div>
    </div>
  )
}
