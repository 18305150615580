import schemeImg from 'pages/customProcess/components/audible/assets/one pagers_learn.png'
import { ProcessCard } from 'pages/customProcess/components/audible/processPhases/processCard/ProcessCard'

const Learn = () => {
  return (
    <ProcessCard
      type="learn"
      title="Learn"
      description="Extraction of lessons from our media activity to inform future campaigns"
      previousLink="/custom/audible/report"
    >
      <img src={schemeImg} alt="" />
    </ProcessCard>
  )
}

export default Learn
