import architectLogoSrc from 'pages/customProcess/components/loreal/assets/architect-logo.png'
import briefLogoSrc from 'pages/customProcess/components/loreal/assets/brief-logo.png'
import cxLogoSrc from 'pages/customProcess/components/loreal/assets/cx-logo.png'
import nexusLogoSrc from 'pages/customProcess/components/loreal/assets/nexus-logo.png'
import oliveLogoSrc from 'pages/customProcess/components/loreal/assets/olive-logo.png'
import synapseOpenLogoSrc from 'pages/customProcess/components/loreal/assets/synapse-logo.png'
import wppOpenLogoSrc from 'pages/customProcess/components/loreal/assets/wpp-open-logo.png'
import wrikeOpenLogoSrc from 'pages/customProcess/components/loreal/assets/wrike-logo.png'

export const processPhasesData = [
  {
    link: '/custom/loreal/media-brief',
    title: 'Media Brief',
    description: 'Briefing the media agency on the campaign objectives and requirements',
    steps: ['BRIEFING', 'Listing queries on brief', 'Seek answers on queries'],
    tools: [
      {
        name: 'WPP Open Brief App',
        iconSrc: wppOpenLogoSrc,
      },
      {
        name: 'Provoke the Brief',
        iconSrc: briefLogoSrc,
      },
      {
        name: 'Architect: Audiences',
        iconSrc: architectLogoSrc,
      },
    ],
    nextLink: '/custom/loreal/strategic-planning',
  },
  {
    link: '/custom/loreal/strategic-planning',
    title: 'Strategic Planning',
    description: 'Developing the media strategy and planning the media mix',
    steps: ['DEVELOP INSIGHTS', 'CREATE PLAN', 'FINALIZE PLAN'],
    tools: [
      {
        name: 'Nexus Growth Platform',
        iconSrc: nexusLogoSrc,
      },
      {
        name: 'Creative Studio',
        iconSrc: briefLogoSrc,
      },
      {
        name: 'CX Journey',
        iconSrc: cxLogoSrc,
      },
      {
        name: 'Architect: Touchpoints',
        iconSrc: architectLogoSrc,
      },
    ],
    previousLink: '/custom/loreal/media-brief',
    nextLink: '/custom/loreal/media-planning',
  },
  {
    link: '/custom/loreal/media-planning',
    title: 'Media Planning\n& Buying\nRequirements',
    description: 'Defining the media channels and buying requirements',
    steps: [
      'INTERNAL COMMS',
      'IDENTIFY MEDIA MIX & PLATFORMS',
      'IDENTIFY KPIS & REPORTS',
      'COMMUNICATE IMPLEMENTATION & CONFIRM DETAILS',
      'OFFICIAL SIGN OFF PROCESSES/ COMPLIANCE REQUIRED',
    ],
    tools: [
      {
        name: 'Olive',
        iconSrc: oliveLogoSrc,
      },
      {
        name: 'Architect: Audience Translator',
        iconSrc: architectLogoSrc,
      },
    ],
    previousLink: '/custom/loreal/strategic-planning',
    nextLink: '/custom/loreal/campaign-implementation',
  },
  {
    link: '/custom/loreal/campaign-implementation',
    title: 'Campaign\nImplementation',
    description: 'Implementing the media campaign and tracking the performance',
    steps: ['PLAN APPROVAL', 'WRIKE BRIEFING', 'MEDIA ACTIVATION', 'GOVERNANCE (CAMPAIGN PERFORMANCE & OPTIMIZATION)'],
    tools: [
      {
        name: 'Architect: Audience Translator',
        iconSrc: architectLogoSrc,
      },
      {
        name: 'Olive',
        iconSrc: oliveLogoSrc,
      },
      {
        name: 'Wrike',
        iconSrc: wrikeOpenLogoSrc,
      },
      {
        name: 'Nexus Growth Platform',
        iconSrc: nexusLogoSrc,
      },
      {
        name: 'Synapse',
        iconSrc: synapseOpenLogoSrc,
      },
    ],
    previousLink: '/custom/loreal/media-planning',
    nextLink: '/custom/loreal/campaign-tracking',
  },
  {
    link: '/custom/loreal/campaign-tracking',
    title: 'Campaign\nTracking &\nReporting',
    description: 'Tracking the campaign performance and reporting the results',
    steps: ['AUTOMATED REPORT GENERATION', 'CAMPAIGN KPI PERFORMANCE VERIFICATION', 'ASSET ANALYSIS'],
    tools: [
      {
        name: 'CX Audit',
        iconSrc: cxLogoSrc,
      },
      {
        name: 'Synapse',
        iconSrc: synapseOpenLogoSrc,
      },
    ],
    previousLink: '/custom/loreal/campaign-implementation',
  },
]
