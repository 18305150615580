// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._esJN{height:calc(100vh - var(--wpp-os-header-height))}.D1ws5{padding:6px 16px;color:#143861;background-color:#f1f9fa}.sFJwl path,.sFJwl g{transition:.35s ease-in-out}@media(width <= 1280px){.sFJwl{transform:scale(0.8)}}.ElVK7{cursor:pointer}.ElVK7 path{transition:.05s ease}.ElVK7 text{font-weight:600;font-size:16px;font-family:var(--wpp-font-family, sans-serif);fill:var(--wpp-grey-color-1000)}", "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/allianz/AllianzProcess.module.scss"],"names":[],"mappings":"AAAA,OACE,gDAAA,CAGF,OACE,gBAAA,CACA,aAAA,CACA,wBAAA,CAIA,qBAEE,2BAAA,CAGF,wBANF,OAOI,oBAAA,CAAA,CAIJ,OACE,cAAA,CAEA,YACE,oBAAA,CAGF,YACE,eAAA,CACA,cAAA,CACA,8CAAA,CACA,+BAAA","sourcesContent":[".root {\n  height: calc(100vh - var(--wpp-os-header-height));\n}\n\n.hint {\n  padding: 6px 16px;\n  color: #143861;\n  background-color: #f1f9fa;\n}\n\n.svg {\n  & path,\n  & g {\n    transition: 0.35s ease-in-out;\n  }\n\n  @media (width <= 1280px) {\n    transform: scale(0.8);\n  }\n}\n\n.svgPoint {\n  cursor: pointer;\n\n  & path {\n    transition: 0.05s ease;\n  }\n\n  & text {\n    font-weight: 600;\n    font-size: 16px;\n    font-family: var(--wpp-font-family, sans-serif);\n    fill: var(--wpp-grey-color-1000);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "_esJN",
	"hint": "D1ws5",
	"svg": "sFJwl",
	"svgPoint": "ElVK7"
};
export default ___CSS_LOADER_EXPORT___;
