import schemeImg from 'pages/customProcess/components/procterAndGamble/assets/allocation.png'
import { ProcessPandGCard } from 'pages/customProcess/components/procterAndGamble/processPhases/processPandGCard/ProcessPandGCard'

const Allocation = () => {
  return (
    <ProcessPandGCard
      title="Allocation"
      description="Cover more markets and cross-market budget allocation to maximize NOS & fast forecasting"
      previousLink="/custom/p&g/audience"
      nextLink="/custom/p&g/activation"
    >
      <img src={schemeImg} alt="" />
    </ProcessPandGCard>
  )
}

export default Allocation
