import { WppActionButton, WppIconChevron, WppTypography, WppButton } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { ReactNode, useMemo } from 'react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import designLogo from 'pages/customProcess/components/audible/processPhases/processCard/assets/design.svg'
import growLogo from 'pages/customProcess/components/audible/processPhases/processCard/assets/grow.svg'
import learnLogo from 'pages/customProcess/components/audible/processPhases/processCard/assets/learn.svg'
import styles from 'pages/customProcess/components/audible/processPhases/processCard/ProcessCard.module.scss'
import { routesManager } from 'utils/routesManager'

interface Props {
  children: ReactNode
  type: 'grow' | 'design' | 'learn'
  title: string
  description: string
  previousLink?: string
  nextLink?: string
  classes?: {
    controls?: string
  }
}

export const ProcessCard = ({ children, type, title, description, previousLink, nextLink, classes }: Props) => {
  const logoImg = useMemo(() => {
    switch (type) {
      case 'design':
        return designLogo
      case 'grow':
        return growLogo
      case 'learn':
        return learnLogo
    }
  }, [type])

  return (
    <>
      <div className={clsx([styles.cutomHeaderr])}>
        <Flex justify="between" gap={24} align="center" className={styles.headerContenttt}>
          <div className={styles.headerContentLeftt}>
            <WppTypography type="4xl-display" className={styles.titleAudible}>
              {title}
            </WppTypography>
            <WppTypography type="s-body" className={styles.whiteText}>
              {description}
            </WppTypography>
          </div>
          <img src={logoImg} alt="" />
        </Flex>
      </div>
      <div className={styles.mainContentWrapperr}>
        <div className={styles.mainContentAudible}>
          <div className={clsx([styles.cardAudible])}>
            <Flex direction="column" justify="between" align="start" gap={24} className={styles.cardContentAudible}>
              <div className={styles.flexGrow}>
                <Flex align="center" className={styles.descriptionCardsWrapperAudible}>
                  <Flex justify="between" gap={24} className={styles.descriptionCardsWrapperInner}>
                    {children}
                  </Flex>
                </Flex>
              </div>
              <Flex align="center" justify="between" className={clsx(classes?.controls, styles.controlsAudible)}>
                <Flex gap={16}>
                  <Link to={previousLink ?? ''}>
                    <WppActionButton disabled={!previousLink} className={styles.navBtn}>
                      <WppIconChevron color="#f58020" direction="left" />
                      Previous phase
                    </WppActionButton>
                  </Link>
                  <Link to={nextLink ?? ''}>
                    <WppActionButton disabled={!nextLink} className={styles.navBtn}>
                      Next phase
                      <WppIconChevron color="#f58020" />
                    </WppActionButton>
                  </Link>
                </Flex>
                <Link to={routesManager.systemUrls.orchestration.root.url}>
                  <WppButton className={styles.projectBtnAudible}>Go to project</WppButton>
                </Link>
              </Flex>
            </Flex>
          </div>
        </div>
      </div>
    </>
  )
}
