import {
  WppIconLogin,
  WppTypography,
  WppIconUnorderedList,
  WppIconPeople,
  WppIconTaskList,
  WppIconDesign,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'

import { Flex } from 'components/common/flex/Flex'
import titleSvgUrl from 'pages/customProcess/components/unilever/processPhases/pages/impact/assets/title.svg'
import styles from 'pages/customProcess/components/unilever/processPhases/pages/impact/Impact.module.scss'
import { ProcessCard } from 'pages/customProcess/components/unilever/processPhases/processCard/ProcessCard'

const Impact = () => {
  return (
    <ProcessCard titleSvg={titleSvgUrl} description="Idea to Execution" previousLink="/custom/ideation">
      <Flex justify="between" gap={24} className={styles.fullWidth}>
        <div className={styles.section}>
          <div className={styles.iconWrapper}>
            <WppIconLogin color="var(--wpp-grey-color-000)" className={styles.inputIcon} />
          </div>
          <Flex direction="column" gap={8}>
            <WppTypography type="l-midi" className={styles.whiteText}>
              Inputs
            </WppTypography>
            <ul className={styles.list}>
              <li>
                <WppTypography type="s-body" className={styles.whiteText}>
                  Campaign Development
                </WppTypography>
              </li>
            </ul>
          </Flex>
        </div>
        <div className={styles.section}>
          <div className={styles.iconWrapper}>
            <WppIconUnorderedList color="var(--wpp-grey-color-000)" className={styles.icon} />
          </div>
          <Flex direction="column" gap={8}>
            <WppTypography type="l-midi" className={styles.whiteText}>
              Activitites
            </WppTypography>
            <div>
              <span className={styles.tagTest}>
                <WppTypography className={styles.tagText}>IDEA DEVELOPMENT</WppTypography>
              </span>
            </div>
            <ul className={styles.list}>
              <li>
                <WppTypography type="s-body" className={styles.whiteText}>
                  {'Development of Campaign\nMaterials across Key Channels'}
                </WppTypography>
              </li>
            </ul>
            <div>
              <span className={styles.tagTest}>
                <WppTypography className={styles.tagText}>IDEA EXTENSION</WppTypography>
              </span>
            </div>
            <ul className={styles.list}>
              <li>
                <WppTypography type="s-body" className={styles.whiteText}>
                  Media Extensions of Idea
                </WppTypography>
              </li>
            </ul>
            <div>
              <span className={styles.tagTest}>
                <WppTypography className={styles.tagText}>OPTIMISATION</WppTypography>
              </span>
            </div>
            <ul className={styles.list}>
              <li>
                <WppTypography type="s-body" className={styles.whiteText}>
                  {'Optimise executions based on\nTesting / Creative Analytics'}
                </WppTypography>
              </li>
            </ul>
          </Flex>
        </div>
        <div className={styles.section}>
          <div className={styles.iconWrapper}>
            <WppIconPeople color="var(--wpp-grey-color-000)" className={styles.icon} />
          </div>
          <Flex direction="column" gap={8}>
            <WppTypography type="l-midi" className={styles.whiteText}>
              Roles involved
            </WppTypography>
            <ul className={styles.list}>
              <li>
                <WppTypography type="s-body" className={styles.whiteText}>
                  Creative
                </WppTypography>
              </li>
              <li>
                <WppTypography type="s-body" className={styles.whiteText}>
                  Strategy
                </WppTypography>
              </li>
              <li>
                <WppTypography type="s-body" className={styles.whiteText}>
                  Media
                </WppTypography>
              </li>
              <li>
                <WppTypography type="s-body" className={styles.whiteText}>
                  Social Media
                </WppTypography>
              </li>
              <li>
                <WppTypography type="s-body" className={styles.whiteText}>
                  Influencer
                </WppTypography>
              </li>
              <li>
                <WppTypography type="s-body" className={styles.whiteText}>
                  Production
                </WppTypography>
              </li>
              <li>
                <WppTypography type="s-body" className={styles.whiteText}>
                  PR
                </WppTypography>
              </li>
              <li>
                <WppTypography type="s-body" className={styles.whiteText}>
                  Creative Technologist
                </WppTypography>
              </li>
              <li>
                <WppTypography type="s-body" className={styles.whiteText}>
                  Tech & Media Partners
                </WppTypography>
              </li>
              <li>
                <WppTypography type="s-body" className={styles.whiteText}>
                  Commerce-Social/Retail
                </WppTypography>
              </li>
            </ul>
          </Flex>
        </div>
        <div className={styles.section}>
          <div className={styles.iconWrapper}>
            <WppIconDesign color="var(--wpp-grey-color-000)" className={styles.icon} />
          </div>
          <Flex direction="column" gap={8}>
            <WppTypography type="l-midi" className={styles.whiteText}>
              Tools
            </WppTypography>
            <ul className={styles.list}>
              <li>
                <WppTypography type="s-body" className={clsx(styles.whiteText, styles.toolName)}>
                  CLV Modeler®
                </WppTypography>
              </li>
              <li>
                <WppTypography type="s-body" className={clsx(styles.whiteText, styles.toolName)}>
                  CLV Tracker®
                </WppTypography>
              </li>
            </ul>
          </Flex>
        </div>
        <div className={styles.section}>
          <div className={styles.iconWrapper}>
            <WppIconTaskList color="var(--wpp-grey-color-000)" className={styles.icon} />
          </div>
          <Flex direction="column" gap={8}>
            <WppTypography type="l-midi" className={styles.whiteText}>
              Outcomes
            </WppTypography>
            <ul className={styles.list}>
              <li>
                <WppTypography type="s-body" className={styles.whiteText}>
                  {'Campaign is developed,\nProduction can be started'}
                </WppTypography>
              </li>
            </ul>
          </Flex>
        </div>
      </Flex>
    </ProcessCard>
  )
}

export default Impact
