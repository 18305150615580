export enum Phases {
  Discover = 'Discover',
  Deliver = 'Deliver',
  Design = 'Design',
}

export enum PhasePoints {
  Adapt = 'Adapt',
  Ambition = 'Ambition',
  Audience = 'Audience',
  Approach = 'Approach',
  Activate = 'Activate',
  Assets = 'Assets',
}

export interface IPointPopupConfig {
  description?: string
  templates?: string
  tools?: { title: string; logo?: string }[]
  button?: {
    title: string
    url: string
  }
  placement: 'left-top' | 'left-bottom' | 'right-top' | 'right-bottom' | 'right-bottom-short' | 'left-top-short'
  parrent: Phases
}
