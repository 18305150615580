import {
  WppIconLogin,
  WppTypography,
  WppIconUnorderedList,
  WppIconPeople,
  WppIconTaskList,
  WppIconDesign,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'

import { Flex } from 'components/common/flex/Flex'
import titleSvgUrl from 'pages/customProcess/components/unilever/processPhases/pages/ideation/assets/title.svg'
import styles from 'pages/customProcess/components/unilever/processPhases/pages/ideation/Ideation.module.scss'
import { ProcessCard } from 'pages/customProcess/components/unilever/processPhases/processCard/ProcessCard'

const Ideation = () => {
  return (
    <ProcessCard
      titleSvg={titleSvgUrl}
      description="Brief to Campaign"
      previousLink="/custom/inspiration"
      nextLink="/custom/impact"
    >
      <Flex justify="between" gap={24} className={styles.fullWidth}>
        <div className={styles.section}>
          <div className={styles.iconWrapper}>
            <WppIconLogin color="var(--wpp-grey-color-000)" className={styles.inputIcon} />
          </div>
          <Flex direction="column" gap={8}>
            <WppTypography type="l-midi" className={styles.whiteText}>
              Inputs
            </WppTypography>
            <ul className={styles.list}>
              <li>
                <WppTypography type="s-body" className={styles.whiteText}>
                  Creative Brief/Ideation POV
                </WppTypography>
              </li>
            </ul>
          </Flex>
        </div>
        <div className={styles.section}>
          <div className={styles.iconWrapper}>
            <WppIconUnorderedList color="var(--wpp-grey-color-000)" className={styles.icon} />
          </div>
          <Flex direction="column" gap={8}>
            <WppTypography type="l-midi" className={styles.whiteText}>
              Activitites
            </WppTypography>
            <div>
              <span className={styles.tagTest}>
                <WppTypography className={styles.tagText}>ideate</WppTypography>
              </span>
            </div>
            <ul className={styles.list}>
              <li>
                <WppTypography type="s-body" className={styles.whiteText}>
                  {'Development of Creative Big Idea\n(integrating Creative and Media)'}
                </WppTypography>
              </li>
            </ul>
            <div>
              <span className={styles.tagTest}>
                <WppTypography className={styles.tagText}>test</WppTypography>
              </span>
            </div>
            <ul className={styles.list}>
              <li>
                <WppTypography type="s-body" className={styles.whiteText}>
                  {'On-going Testing (Qual/Quant/\nCreative Analytics)'}
                </WppTypography>
              </li>
            </ul>
            <div>
              <span className={styles.tagTest}>
                <WppTypography className={styles.tagText}>brief</WppTypography>
              </span>
            </div>
            <ul className={styles.list}>
              <li>
                <WppTypography type="s-body" className={styles.whiteText}>
                  {'Provide Briefs to Expert Squads\nto evolve idea further'}
                </WppTypography>
              </li>
            </ul>
          </Flex>
        </div>
        <div className={styles.section}>
          <div className={styles.iconWrapper}>
            <WppIconPeople color="var(--wpp-grey-color-000)" className={styles.icon} />
          </div>
          <Flex direction="column" gap={8}>
            <WppTypography type="l-midi" className={styles.whiteText}>
              Roles involved
            </WppTypography>
            <ul className={styles.list}>
              <li>
                <WppTypography type="s-body" className={styles.whiteText}>
                  Creative
                </WppTypography>
              </li>
              <li>
                <WppTypography type="s-body" className={styles.whiteText}>
                  Strategy
                </WppTypography>
              </li>
              <li>
                <WppTypography type="s-body" className={styles.whiteText}>
                  Media
                </WppTypography>
              </li>
              <li>
                <WppTypography type="s-body" className={styles.whiteText}>
                  Social Media
                </WppTypography>
              </li>
              <li>
                <WppTypography type="s-body" className={styles.whiteText}>
                  Influencer
                </WppTypography>
              </li>
              <li>
                <WppTypography type="s-body" className={styles.whiteText}>
                  Production
                </WppTypography>
              </li>
              <li>
                <WppTypography type="s-body" className={styles.whiteText}>
                  Creative Technologist
                </WppTypography>
              </li>
              <li>
                <WppTypography type="s-body" className={styles.whiteText}>
                  PR
                </WppTypography>
              </li>
              <li>
                <WppTypography type="s-body" className={styles.whiteText}>
                  Tech & Media Partners
                </WppTypography>
              </li>
              <li>
                <WppTypography type="s-body" className={styles.whiteText}>
                  Commerce-Social/Retail
                </WppTypography>
              </li>
            </ul>
          </Flex>
        </div>
        <div className={styles.section}>
          <div className={styles.iconWrapper}>
            <WppIconDesign color="var(--wpp-grey-color-000)" className={styles.icon} />
          </div>
          <Flex direction="column" gap={8}>
            <WppTypography type="l-midi" className={styles.whiteText}>
              Tools
            </WppTypography>
            <ul className={styles.list}>
              <li>
                <WppTypography type="s-body" className={clsx(styles.whiteText, styles.toolName)}>
                  Imagine®
                </WppTypography>
              </li>
              <li>
                <WppTypography type="s-body" className={clsx(styles.whiteText, styles.toolName)}>
                  Miro®
                </WppTypography>
              </li>
            </ul>
          </Flex>
        </div>
        <div className={styles.section}>
          <div className={styles.iconWrapper}>
            <WppIconTaskList color="var(--wpp-grey-color-000)" className={styles.icon} />
          </div>
          <Flex direction="column" gap={8}>
            <WppTypography type="l-midi" className={styles.whiteText}>
              Outcomes
            </WppTypography>
            <ul className={styles.list}>
              <li>
                <WppTypography type="s-body" className={styles.whiteText}>
                  {'Big Idea is aligned to move\nforward to Execution'}
                </WppTypography>
              </li>
            </ul>
          </Flex>
        </div>
      </Flex>
    </ProcessCard>
  )
}

export default Ideation
