import { MayBeNull } from '@wpp-open/core'
import { MouseEventHandler, RefObject, useCallback } from 'react'

import image0_618_3370 from 'pages/customProcess/components/allianz/assets/image0_618_3370.png'
import image1_618_3370 from 'pages/customProcess/components/allianz/assets/image1_618_3370.png'
import { Phases, PhasePoints } from 'pages/customProcess/components/allianz/types'

const phasePaths = {
  [Phases.Deliver]: {
    inactive:
      'M177.853 485C152.401 440.916 139.001 390.91 139 340.007C138.999 289.104 152.396 239.097 177.846 195.012C203.295 150.927 239.9 114.318 283.982 88.8629C328.064 63.4081 378.069 50.0048 428.973 50L428.98 125.4C391.311 125.404 354.307 135.322 321.687 154.159C289.066 172.995 261.979 200.086 243.146 232.709C224.313 265.331 214.399 302.337 214.4 340.005C214.401 377.674 224.317 414.678 243.151 447.3L177.853 485Z',
    active:
      'M177.853 485C152.401 440.916 139.001 390.91 139 340.007C138.999 289.103 152.396 239.097 177.846 195.012C203.295 150.927 239.9 114.318 283.982 88.8628C328.064 63.4081 378.069 50.0047 428.973 49.9999L428.991 241.4C411.684 241.402 394.682 245.959 379.694 254.613C364.706 263.268 352.26 275.715 343.608 290.704C334.955 305.693 330.4 322.695 330.4 340.002C330.4 357.309 334.956 374.312 343.61 389.3L177.853 485Z',
  },
  [Phases.Design]: {
    inactive:
      'M680.521 484.352C655.14 528.575 618.548 565.326 574.433 590.897C530.319 616.468 480.243 629.955 429.253 630C378.264 630.044 328.164 616.644 284.005 591.15C239.846 565.656 203.19 528.97 177.732 484.791L243.062 447.145C261.901 479.838 289.026 506.986 321.704 525.851C354.381 544.717 391.455 554.633 429.187 554.6C466.92 554.567 503.976 544.586 536.621 525.664C569.265 506.741 596.343 479.546 615.125 446.82L680.521 484.352Z',
    active:
      'M680.521 484.352C655.14 528.575 618.548 565.326 574.433 590.897C530.319 616.468 480.243 629.955 429.253 630C378.264 630.044 328.164 616.644 284.005 591.15C239.846 565.656 203.19 528.97 177.732 484.791L368.696 374.75C374.806 385.353 383.603 394.158 394.201 400.276C404.799 406.395 416.823 409.611 429.061 409.6C441.298 409.589 453.317 406.352 463.904 400.215C474.491 394.078 483.274 385.258 489.365 374.644L680.521 484.352Z',
  },
  [Phases.Discover]: {
    inactive:
      'M429 50C479.9 50 529.904 63.3971 573.987 88.845C618.069 114.293 654.677 150.895 680.132 194.974C705.587 239.052 718.992 289.054 719 339.954C719.008 390.855 705.619 440.861 680.178 484.947L614.872 447.261C633.698 414.637 643.606 377.633 643.6 339.966C643.594 302.3 633.674 265.299 614.838 232.681C596.001 200.062 568.911 172.977 536.29 154.145C503.669 135.314 466.666 125.4 429 125.4V50Z',
    active:
      'M429 50C479.9 50 529.904 63.3971 573.987 88.845C618.069 114.293 654.677 150.895 680.132 194.974C705.587 239.052 718.992 289.054 719 339.954C719.008 390.855 705.619 440.861 680.178 484.947L514.4 389.282C523.05 374.293 527.603 357.291 527.6 339.985C527.597 322.678 523.04 305.678 514.385 290.691C505.73 275.704 493.284 263.26 478.296 254.607C463.307 245.955 446.306 241.4 429 241.4V50Z',
  },
}

export const AllianzSvg = ({
  activePhase,
  activePoint,
  className,
  pointClassName,
  pointRefs,
  onPhaseEnter,
  onPhaseLeave,
  onPointClick,
}: {
  activePhase: MayBeNull<Phases>
  activePoint: MayBeNull<PhasePoints>
  className?: string
  pointClassName?: string
  pointRefs: RefObject<Record<PhasePoints, any>>
  onPhaseEnter: (phase: Phases) => MouseEventHandler
  onPhaseLeave: MouseEventHandler
  onPointClick: (point: PhasePoints) => MouseEventHandler
}) => {
  const getPhasePath = (phase: Phases) =>
    phase === activePhase ? phasePaths[phase].active : phasePaths[phase].inactive

  const isActivePhase = useCallback((phase: Phases) => !activePhase || phase === activePhase, [activePhase])
  const isActivePoint = useCallback((point: PhasePoints) => point === activePoint, [activePoint])
  const fillRef = useCallback(
    (name: PhasePoints) => (ref: any) => {
      if (!pointRefs.current) return
      pointRefs.current[name] = ref
    },
    [pointRefs],
  )

  return (
    <svg
      width="1000"
      height="680"
      viewBox="0 0 862 680"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
    >
      <circle cx="429" cy="340" r="340" fill="#143861" fillOpacity="0.05" />
      <circle cx="429" cy="340" r="339.5" stroke="url(#pattern0)" />
      <g id={Phases.Deliver} onMouseEnter={onPhaseEnter(Phases.Deliver)} onMouseLeave={onPhaseLeave}>
        <path d={getPhasePath(Phases.Deliver)} fill="#2A77CF" opacity={isActivePhase(Phases.Deliver) ? 1 : 0.3} />

        <g
          id="Assets"
          onClick={onPointClick(PhasePoints.Assets)}
          className={pointClassName}
          ref={fillRef(PhasePoints.Assets)}
        >
          <path
            d="M89.0007 332.001C93.419 332.001 97.0007 335.583 97.0007 340.001C97.0007 344.419 93.419 348.001 89.0007 348.001C84.5825 348.001 81.0007 344.419 81.0007 340.001C81.0007 335.583 84.5825 332.001 89.0007 332.001Z"
            fill={isActivePhase(Phases.Deliver) ? '#008ED6' : '#96CEEB'}
          />
          <path
            d="M101.001 340.001C101.001 333.373 95.6281 328.001 89.0007 328.001C82.3733 328.001 77.0007 333.373 77.0007 340.001C77.0007 346.628 82.3733 352.001 89.0007 352.001C95.6281 352.001 101.001 346.628 101.001 340.001Z"
            stroke="#008ED6"
            strokeOpacity="0.16"
            strokeWidth={isActivePoint(PhasePoints.Assets) ? 20 : 8}
          />

          <text x="-70" y="345" opacity={isActivePhase(Phases.Deliver) ? 1 : 0.3}>
            Assets and Ideas
          </text>
        </g>

        <g
          id="Activate"
          onClick={onPointClick(PhasePoints.Activate)}
          className={pointClassName}
          ref={fillRef(PhasePoints.Activate)}
        >
          <path
            d="M265.93 42.0021C268.139 45.8287 266.827 50.7213 263.001 52.93C259.174 55.1387 254.281 53.8271 252.073 50.0006C249.864 46.174 251.176 41.2814 255.002 39.0727C258.829 36.864 263.721 38.1755 265.93 42.0021Z"
            fill={isActivePhase(Phases.Deliver) ? '#008ED6' : '#96CEEB'}
          />
          <path
            d="M265 56.3943C270.74 53.0813 272.707 45.7424 269.394 40.0025C266.081 34.2626 258.742 32.2953 253.003 35.6084C247.263 38.9214 245.295 46.2603 248.608 52.0002C251.921 57.74 259.26 59.7074 265 56.3943Z"
            stroke="#008ED6"
            strokeOpacity="0.16"
            strokeWidth={isActivePoint(PhasePoints.Activate) ? 20 : 8}
          />

          <text x="150" y="50" opacity={isActivePhase(Phases.Deliver) ? 1 : 0.3}>
            Activation
          </text>
        </g>

        <path
          d="M186.663 279.744C188.118 280.054 189.319 280.597 190.267 281.371C191.201 282.127 191.918 283.025 192.416 284.063C192.902 285.083 193.191 286.184 193.282 287.365C193.358 288.543 193.274 289.703 193.03 290.845L191.708 297.041L175.09 293.495L176.372 287.486C176.623 286.313 177.011 285.185 177.536 284.103C178.046 283.017 178.717 282.097 179.549 281.342C180.365 280.584 181.364 280.044 182.544 279.723C183.725 279.403 185.098 279.41 186.663 279.744ZM185.771 283.921C184.832 283.721 184.025 283.712 183.347 283.895C182.658 284.059 182.078 284.36 181.608 284.8C181.126 285.22 180.736 285.743 180.44 286.366C180.146 286.975 179.926 287.623 179.779 288.311L179.353 290.306L189.07 292.38L189.476 290.479C189.63 289.759 189.699 289.054 189.682 288.364C189.654 287.654 189.505 287.009 189.237 286.428C188.971 285.831 188.557 285.318 187.995 284.887C187.436 284.44 186.695 284.118 185.771 283.921Z"
          fill="white"
        />
        <path
          d="M197.132 273.742L180.873 268.804L184.193 257.873L187.523 258.884L185.354 266.026L188.408 266.954L190.459 260.202L193.605 261.158L191.554 267.909L194.93 268.935L197.225 261.379L200.578 262.398L197.132 273.742Z"
          fill="white"
        />
        <path
          d="M203.253 254.772L187.399 248.66L188.883 244.808L201.401 249.634L203.767 243.498L207.104 244.785L203.253 254.772Z"
          fill="white"
        />
        <path d="M210.012 238.149L194.549 231.104L196.261 227.347L211.723 234.393L210.012 238.149Z" fill="white" />
        <path
          d="M219.5 219.015L217.551 222.626L199.543 220.217L201.731 216.162L214.203 218.42L214.249 218.335L205.504 209.171L207.658 205.179L219.5 219.015Z"
          fill="white"
        />
        <path
          d="M226.247 207.316L211.993 198.066L218.212 188.483L221.132 190.378L217.068 196.639L219.746 198.376L223.587 192.458L226.345 194.248L222.504 200.166L225.463 202.087L229.762 195.463L232.701 197.371L226.247 207.316Z"
          fill="white"
        />
        <path
          d="M242.893 183.554L235.308 182.362L234.46 183.466L239.811 187.572L237.356 190.77L223.875 180.426L227.82 175.285C228.317 174.638 228.869 174.063 229.476 173.561C230.08 173.037 230.726 172.655 231.414 172.416C232.111 172.164 232.832 172.092 233.576 172.2C234.32 172.307 235.08 172.658 235.854 173.252C236.768 173.953 237.346 174.79 237.588 175.762C237.83 176.735 237.749 177.761 237.343 178.842L245.815 179.746L242.893 183.554ZM233.442 176.514C233.125 176.27 232.817 176.145 232.52 176.139C232.219 176.11 231.936 176.164 231.668 176.302C231.398 176.418 231.142 176.594 230.899 176.831C230.666 177.056 230.457 177.289 230.272 177.53L228.943 179.263L232.085 181.673L233.268 180.131C233.473 179.865 233.663 179.577 233.839 179.269C234.003 178.95 234.115 178.633 234.176 178.317C234.237 178.001 234.213 177.69 234.105 177.385C234.006 177.067 233.785 176.777 233.442 176.514Z"
          fill="white"
        />
      </g>

      <g id={Phases.Design} onMouseEnter={onPhaseEnter(Phases.Design)} onMouseLeave={onPhaseLeave}>
        <path d={getPhasePath(Phases.Design)} fill="#1F5796" opacity={isActivePhase(Phases.Design) ? 1 : 0.3} />

        <g
          id="Audience"
          onClick={onPointClick(PhasePoints.Audience)}
          className={pointClassName}
          ref={fillRef(PhasePoints.Audience)}
        >
          <path
            d="M591.073 638C588.864 634.174 590.176 629.281 594.002 627.073C597.829 624.864 602.721 626.175 604.93 630.002C607.139 633.829 605.827 638.721 602.001 640.93C598.174 643.139 593.281 641.827 591.073 638Z"
            fill={isActivePhase(Phases.Design) ? '#1F5796' : '#A1BADB'}
          />
          <path
            d="M592.003 623.608C586.263 626.921 584.295 634.26 587.608 640C590.921 645.74 598.26 647.707 604 644.394C609.74 641.081 611.707 633.742 608.394 628.002C605.081 622.263 597.742 620.295 592.003 623.608Z"
            stroke="#1F5796"
            strokeOpacity="0.16"
            strokeWidth={isActivePoint(PhasePoints.Audience) ? 20 : 8}
          />
          <text x="600" y="670" opacity={isActivePhase(Phases.Design) ? 1 : 0.3}>
            Audience
          </text>
        </g>
        <g
          id="Approach"
          onClick={onPointClick(PhasePoints.Approach)}
          className={pointClassName}
          ref={fillRef(PhasePoints.Approach)}
        >
          <path
            d="M252.074 630C254.283 626.174 259.176 624.864 263.002 627.073C266.828 629.283 268.139 634.176 265.929 638.002C263.72 641.828 258.827 643.139 255.001 640.929C251.174 638.719 249.864 633.827 252.074 630Z"
            fill={isActivePhase(Phases.Design) ? '#1F5796' : '#A1BADB'}
          />
          <path
            d="M265.003 623.61C259.263 620.295 251.924 622.261 248.61 628C245.295 633.739 247.261 641.078 253 644.393C258.739 647.707 266.079 645.742 269.393 640.002C272.707 634.263 270.742 626.924 265.003 623.61Z"
            stroke="#1F5796"
            strokeOpacity="0.16"
            strokeWidth={isActivePoint(PhasePoints.Approach) ? 20 : 8}
          />
          <text x="185" y="670" opacity={isActivePhase(Phases.Design) ? 1 : 0.3}>
            Approach
          </text>
        </g>

        <path
          d="M389.337 590.259C388.977 591.703 388.394 592.884 387.587 593.805C386.8 594.713 385.879 595.399 384.824 595.861C383.788 596.312 382.678 596.563 381.495 596.614C380.315 596.65 379.158 596.526 378.025 596.244L371.877 594.711L375.988 578.224L381.95 579.71C383.114 580 384.228 580.427 385.292 580.989C386.359 581.535 387.256 582.237 387.982 583.094C388.712 583.936 389.217 584.952 389.497 586.143C389.778 587.334 389.724 588.706 389.337 590.259ZM385.192 589.225C385.424 588.294 385.461 587.487 385.301 586.804C385.161 586.109 384.879 585.519 384.456 585.035C384.052 584.538 383.543 584.131 382.93 583.813C382.332 583.499 381.692 583.257 381.009 583.087L379.029 582.593L376.626 592.234L378.512 592.704C379.226 592.882 379.928 592.975 380.619 592.982C381.329 592.978 381.979 592.852 382.569 592.603C383.174 592.358 383.702 591.962 384.151 591.414C384.617 590.871 384.964 590.141 385.192 589.225Z"
          fill="white"
        />
        <path
          d="M395.384 599.991L397.72 583.16L409.036 584.73L408.557 588.177L401.164 587.151L400.725 590.313L407.714 591.283L407.262 594.54L400.273 593.57L399.789 597.064L407.61 598.15L407.128 601.62L395.384 599.991Z"
          fill="white"
        />
        <path
          d="M424.819 590.144C424.485 589.681 424.036 589.311 423.471 589.031C422.923 588.736 422.385 588.578 421.857 588.556C421.586 588.545 421.305 588.558 421.015 588.594C420.741 588.631 420.49 588.716 420.26 588.851C420.031 588.97 419.832 589.138 419.663 589.355C419.51 589.557 419.427 589.826 419.413 590.161C419.401 590.449 419.447 590.691 419.551 590.888C419.671 591.085 419.84 591.26 420.058 591.413C420.292 591.567 420.566 591.714 420.881 591.855C421.196 591.98 421.551 592.115 421.945 592.259C422.513 592.475 423.103 592.715 423.717 592.981C424.331 593.23 424.886 593.557 425.382 593.962C425.878 594.367 426.282 594.864 426.594 595.453C426.906 596.026 427.045 596.737 427.01 597.584C426.97 598.559 426.752 599.399 426.354 600.103C425.974 600.792 425.47 601.356 424.844 601.794C424.217 602.233 423.507 602.548 422.715 602.74C421.922 602.931 421.11 603.01 420.279 602.976C419.064 602.926 417.898 602.67 416.78 602.207C415.662 601.729 414.744 601.083 414.025 600.268L416.823 597.645C417.218 598.174 417.744 598.628 418.401 599.007C419.074 599.371 419.747 599.567 420.418 599.595C420.722 599.607 421.019 599.587 421.309 599.535C421.6 599.483 421.852 599.389 422.066 599.254C422.295 599.119 422.479 598.935 422.617 598.7C422.755 598.466 422.83 598.181 422.844 597.845C422.857 597.525 422.789 597.25 422.638 597.02C422.487 596.789 422.264 596.58 421.967 596.392C421.687 596.188 421.327 596.005 420.885 595.843C420.46 595.665 419.971 595.477 419.419 595.278C418.883 595.08 418.356 594.85 417.838 594.588C417.336 594.327 416.885 594.005 416.485 593.62C416.101 593.22 415.792 592.751 415.558 592.212C415.34 591.659 415.247 590.999 415.279 590.231C415.318 589.288 415.543 588.489 415.954 587.833C416.365 587.177 416.892 586.647 417.533 586.241C418.174 585.835 418.89 585.552 419.682 585.392C420.473 585.217 421.269 585.145 422.068 585.178C423.028 585.218 423.995 585.434 424.972 585.826C425.965 586.219 426.823 586.775 427.546 587.493L424.819 590.144Z"
          fill="white"
        />
        <path d="M435.398 602.683L434.74 585.704L438.865 585.544L439.522 602.523L435.398 602.683Z" fill="white" />
        <path
          d="M463.31 599.084C462.54 599.619 461.617 600.093 460.541 600.506C459.481 600.917 458.3 601.206 456.999 601.374C455.65 601.548 454.377 601.494 453.179 601.213C451.997 600.93 450.943 600.453 450.018 599.781C449.108 599.108 448.354 598.262 447.755 597.242C447.153 596.206 446.767 595.022 446.595 593.689C446.421 592.34 446.493 591.089 446.812 589.934C447.147 588.778 447.678 587.766 448.405 586.898C449.13 586.014 450.021 585.294 451.079 584.738C452.137 584.182 453.308 583.822 454.593 583.656C455.926 583.484 457.191 583.531 458.386 583.796C459.58 584.045 460.578 584.465 461.38 585.056L459.187 588.315C458.731 587.906 458.159 587.6 457.472 587.399C456.782 587.181 456.025 587.125 455.2 587.232C454.486 587.324 453.844 587.544 453.276 587.891C452.706 588.223 452.229 588.655 451.846 589.189C451.461 589.706 451.184 590.307 451.014 590.99C450.858 591.655 450.829 592.369 450.927 593.131C451.027 593.908 451.23 594.608 451.536 595.23C451.842 595.852 452.24 596.373 452.73 596.794C453.234 597.197 453.82 597.492 454.49 597.68C455.173 597.85 455.928 597.882 456.753 597.776C457.229 597.714 457.677 597.624 458.097 597.506C458.515 597.371 458.897 597.201 459.241 596.995L458.845 593.925L455.632 594.339L455.208 591.054L462.159 590.158L463.31 599.084Z"
          fill="white"
        />
        <path
          d="M482.789 595.847L473.464 586.72L473.394 586.737L476.176 597.496L472.31 598.46L468.199 581.973L472.74 580.84L482.036 589.95L482.106 589.933L479.331 579.197L483.196 578.233L487.307 594.721L482.789 595.847Z"
          fill="white"
        />
      </g>

      <g id={Phases.Discover} onMouseEnter={onPhaseEnter(Phases.Discover)} onMouseLeave={onPhaseLeave}>
        <path d={getPhasePath(Phases.Discover)} fill="#143861" />

        <g
          id="Adapt"
          onClick={onPointClick(PhasePoints.Adapt)}
          className={pointClassName}
          ref={fillRef(PhasePoints.Adapt)}
        >
          <path
            d="M605.929 49.0021C603.72 52.8282 598.827 54.1387 595.001 51.9291C591.174 49.7195 589.864 44.8266 592.074 41.0005C594.283 37.1745 599.176 35.864 603.002 38.0736C606.828 40.2832 608.139 45.176 605.929 49.0021Z"
            fill={isActivePhase(Phases.Discover) ? '#212C65' : '#9DA9E7'}
          />
          <path
            d="M593 55.393C598.739 58.7073 606.079 56.7417 609.393 51.0025C612.707 45.2634 610.742 37.9241 605.003 34.6097C599.263 31.2954 591.924 33.261 588.61 39.0002C585.295 44.7393 587.261 52.0786 593 55.393Z"
            stroke="#212C65"
            strokeOpacity="0.16"
            strokeWidth={isActivePoint(PhasePoints.Adapt) ? 20 : 8}
          />
          <text x="630" y="50" opacity={isActivePhase(Phases.Discover) ? 1 : 0.3}>
            Adapt
          </text>
        </g>

        <g
          id="Ambition"
          onClick={onPointClick(PhasePoints.Ambition)}
          className={pointClassName}
          ref={fillRef(PhasePoints.Ambition)}
        >
          <path
            d="M767.999 348C763.581 347.999 759.999 344.417 760 339.999C760.001 335.581 763.583 331.999 768.001 332C772.419 332.001 776.001 335.583 776 340.001C775.999 344.419 772.417 348.001 767.999 348Z"
            fill={isActivePhase(Phases.Discover) ? '#212C65' : '#9DA9E7'}
          />
          <path
            d="M756 339.999C755.999 346.626 761.371 351.999 767.999 352C774.626 352.001 779.999 346.629 780 340.001C780.001 333.374 774.629 328.001 768.001 328C761.374 327.999 756.001 333.371 756 339.999Z"
            stroke="#212C65"
            strokeOpacity="0.16"
            strokeWidth={isActivePoint(PhasePoints.Ambition) ? 20 : 8}
          />
          <text x="790" y="345" opacity={isActivePhase(Phases.Discover) ? 1 : 0.3}>
            Ambition
          </text>
        </g>

        <path
          d="M611.095 166.7C610.035 167.743 608.92 168.447 607.752 168.811C606.606 169.176 605.463 169.28 604.321 169.124C603.203 168.968 602.125 168.603 601.089 168.029C600.064 167.444 599.142 166.735 598.322 165.902L593.878 161.387L605.988 149.467L610.298 153.846C611.14 154.701 611.868 155.646 612.482 156.681C613.107 157.704 613.506 158.771 613.678 159.881C613.862 160.98 613.762 162.11 613.38 163.273C612.997 164.435 612.236 165.577 611.095 166.7ZM608.099 163.655C608.783 162.982 609.235 162.312 609.455 161.646C609.698 160.981 609.766 160.331 609.657 159.696C609.572 159.062 609.351 158.449 608.993 157.858C608.647 157.278 608.227 156.737 607.733 156.236L606.302 154.782L599.221 161.752L600.585 163.137C601.101 163.662 601.652 164.107 602.237 164.474C602.845 164.841 603.466 165.072 604.098 165.168C604.742 165.275 605.399 165.212 606.069 164.98C606.749 164.759 607.426 164.317 608.099 163.655Z"
          fill="white"
        />
        <path d="M610.331 178.771L623.214 167.691L625.906 170.82L613.023 181.9L610.331 178.771Z" fill="white" />
        <path
          d="M634.106 188.211C634.249 187.66 634.248 187.077 634.103 186.464C633.981 185.854 633.759 185.338 633.44 184.918C633.275 184.702 633.086 184.493 632.874 184.293C632.671 184.106 632.444 183.967 632.194 183.875C631.957 183.775 631.7 183.728 631.425 183.737C631.172 183.748 630.912 183.856 630.645 184.059C630.416 184.233 630.259 184.423 630.174 184.629C630.098 184.847 630.071 185.089 630.092 185.354C630.123 185.632 630.184 185.937 630.276 186.27C630.381 186.592 630.503 186.951 630.644 187.347C630.84 187.922 631.031 188.53 631.218 189.172C631.418 189.804 631.52 190.44 631.524 191.08C631.528 191.72 631.403 192.348 631.148 192.965C630.906 193.571 630.448 194.131 629.773 194.645C628.996 195.236 628.21 195.603 627.414 195.746C626.64 195.892 625.885 195.863 625.148 195.661C624.41 195.458 623.715 195.112 623.062 194.624C622.409 194.136 621.831 193.56 621.327 192.898C620.59 191.93 620.044 190.869 619.687 189.713C619.343 188.547 619.255 187.428 619.424 186.354L623.229 186.837C623.073 187.478 623.059 188.172 623.186 188.92C623.335 189.671 623.613 190.314 624.02 190.849C624.204 191.091 624.409 191.307 624.634 191.498C624.859 191.688 625.092 191.822 625.333 191.901C625.583 191.992 625.842 192.016 626.111 191.972C626.379 191.929 626.647 191.805 626.915 191.602C627.169 191.408 627.337 191.18 627.419 190.917C627.5 190.654 627.519 190.348 627.475 190C627.453 189.654 627.364 189.259 627.207 188.816C627.073 188.375 626.907 187.879 626.707 187.326C626.518 186.787 626.359 186.234 626.23 185.669C626.111 185.116 626.072 184.563 626.113 184.009C626.177 183.458 626.341 182.921 626.606 182.397C626.894 181.877 627.343 181.384 627.954 180.919C628.706 180.348 629.465 180.011 630.232 179.91C630.999 179.808 631.744 179.875 632.466 180.11C633.187 180.345 633.862 180.717 634.489 181.224C635.13 181.722 635.692 182.29 636.176 182.926C636.758 183.69 637.209 184.574 637.529 185.576C637.859 186.591 637.978 187.607 637.886 188.622L634.106 188.211Z"
          fill="white"
        />
        <path
          d="M640.6 215.992C639.466 215.83 638.385 215.425 637.355 214.776C636.339 214.118 635.44 213.185 634.658 211.976C633.945 210.875 633.466 209.736 633.22 208.561C632.983 207.4 632.972 206.263 633.187 205.152C633.416 204.033 633.868 202.978 634.542 201.989C635.217 201 636.112 200.144 637.227 199.423C638.369 198.684 639.53 198.21 640.709 198C641.896 197.803 643.038 197.846 644.135 198.127C645.245 198.4 646.279 198.893 647.237 199.608C648.195 200.322 649.025 201.223 649.729 202.311C650.381 203.319 650.839 204.424 651.102 205.626C651.387 206.832 651.382 207.959 651.088 209.007L647.239 208.182C647.473 207.573 647.529 206.937 647.405 206.274C647.281 205.611 647.041 205.004 646.684 204.453C646.293 203.848 645.818 203.365 645.26 203.002C644.723 202.644 644.143 202.41 643.519 202.299C642.894 202.188 642.248 202.206 641.581 202.352C640.926 202.49 640.283 202.763 639.652 203.171C639.007 203.588 638.481 204.071 638.074 204.621C637.667 205.17 637.382 205.745 637.218 206.346C637.077 206.952 637.06 207.573 637.166 208.21C637.273 208.846 637.517 209.459 637.9 210.05C638.343 210.735 638.864 211.246 639.463 211.583C640.062 211.92 640.676 212.103 641.306 212.134L640.6 215.992Z"
          fill="white"
        />
        <path
          d="M658.04 234.918C656.853 235.547 655.655 235.918 654.448 236.033C653.256 236.139 652.111 236.012 651.015 235.651C649.94 235.297 648.946 234.72 648.032 233.918C647.119 233.116 646.351 232.129 645.73 230.955C645.108 229.782 644.722 228.592 644.572 227.386C644.43 226.194 644.51 225.047 644.813 223.944C645.138 222.849 645.68 221.838 646.438 220.911C647.211 219.977 648.191 219.195 649.379 218.566C650.581 217.929 651.771 217.561 652.949 217.462C654.142 217.355 655.269 217.483 656.33 217.844C657.398 218.22 658.378 218.805 659.27 219.6C660.169 220.41 660.929 221.401 661.551 222.574C662.173 223.748 662.565 224.934 662.73 226.133C662.894 227.331 662.827 228.471 662.531 229.551C662.242 230.646 661.718 231.657 660.959 232.584C660.215 233.503 659.242 234.281 658.04 234.918ZM655.984 231.036C656.635 230.692 657.187 230.264 657.64 229.752C658.093 229.24 658.416 228.689 658.61 228.097C658.825 227.513 658.904 226.892 658.846 226.235C658.809 225.584 658.622 224.941 658.285 224.305C657.948 223.668 657.521 223.152 657.003 222.756C656.5 222.353 655.942 222.069 655.33 221.905C654.732 221.733 654.094 221.69 653.416 221.778C652.745 221.879 652.085 222.102 651.435 222.447C650.756 222.806 650.183 223.246 649.716 223.765C649.262 224.277 648.928 224.825 648.713 225.41C648.505 226.009 648.419 226.633 648.456 227.284C648.507 227.927 648.701 228.566 649.038 229.203C649.375 229.839 649.795 230.359 650.299 230.762C650.816 231.158 651.381 231.438 651.993 231.602C652.605 231.767 653.25 231.806 653.928 231.718C654.62 231.623 655.306 231.396 655.984 231.036Z"
          fill="white"
        />
        <path
          d="M657.19 254.15L655.592 250.37L668.737 237.828L670.532 242.073L660.968 250.39L661.005 250.478L673.625 249.389L675.392 253.567L657.19 254.15Z"
          fill="white"
        />
        <path
          d="M662.117 266.728L678.226 261.321L681.861 272.151L678.562 273.259L676.187 266.183L673.161 267.198L675.406 273.888L672.289 274.934L670.044 268.244L666.699 269.367L669.212 276.853L665.89 277.968L662.117 266.728Z"
          fill="white"
        />
        <path
          d="M670.424 294.532L676.053 289.309L675.71 287.96L669.174 289.625L668.179 285.717L684.645 281.523L686.245 287.802C686.446 288.593 686.556 289.382 686.575 290.17C686.614 290.969 686.514 291.713 686.277 292.401C686.043 293.104 685.654 293.715 685.109 294.234C684.564 294.752 683.819 295.132 682.873 295.373C681.757 295.657 680.742 295.594 679.828 295.183C678.914 294.772 678.158 294.073 677.56 293.086L671.609 299.183L670.424 294.532ZM681.802 291.46C682.19 291.362 682.478 291.197 682.667 290.967C682.876 290.749 683.009 290.493 683.066 290.197C683.142 289.914 683.162 289.603 683.125 289.266C683.093 288.944 683.039 288.636 682.964 288.341L682.425 286.225L678.588 287.202L679.067 289.086C679.15 289.411 679.259 289.739 679.392 290.068C679.541 290.393 679.721 290.678 679.931 290.921C680.142 291.165 680.401 291.338 680.708 291.442C681.019 291.561 681.384 291.567 681.802 291.46Z"
          fill="white"
        />
      </g>

      <circle id="logoCircle" cx="429" cy="340" r={activePhase ? 172 : 214} fill="white" />
      <rect x="285" y="244" width="289" height="199" fill="url(#pattern1)" />
      <defs>
        <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlinkHref="#image0_618_3370" transform="translate(-0.388889) scale(0.000888889)" />
        </pattern>
        <pattern id="pattern1" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlinkHref="#image1_618_3370" transform="matrix(0.00182815 0 0 0.00265496 0 -0.000459335)" />
        </pattern>
        <image id="image0_618_3370" width="2000" height="1125" xlinkHref={image0_618_3370} />
        <image id="image1_618_3370" width="547" height="377" xlinkHref={image1_618_3370} />
      </defs>
    </svg>
  )
}
