export const IkeaProcessData = [
  {
    title: 'Opportunity Definition',
    description: 'Clearly outlining the key challenge and opportunity for communications and the role media will play',
  },
  {
    title: 'Audience Analysis',
    description: 'Define approach to audiences and key data and insight opportunities to address effectively',
  },
  {
    title: 'Strategic &\nMeasurement Solutions',
    link: '/custom/strategy-measurement-solutions',
    description:
      'Propose how the communication solution will work, the assets to build and the framework to use for evaluating the impact and effectiveness of activity',
  },
  {
    title: 'Channel Allocation',
    description:
      'Using proprietary IKEA and benchmark data recommend an optimal, outcome-based split of media budgets across channels',
  },
  {
    title: 'Execution Plan',
    description: 'In-channel implementation detail and tactics including ‘built for’ platform ideas',
  },
  {
    title: 'Reporting, Optimisation &\nMeasurement',
    description:
      'How to adapt targeting and messaging based on response and how will results inform better future planning',
  },
]
