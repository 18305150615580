export enum Phases {
  Grow = 'Grow',
  Design = 'Design',
  Learn = 'Learn',
}

export enum PhasePoints {
  Provoke = 'Provoke',
  Identify = 'Identify',
  Strategize = 'Strategize',
  Plan = 'Plan',
  Activate = 'Activate',
  Deploy = 'Deploy',
  Report = 'Report',
  Learn = 'Learn',
}
